<template>
  <div class="pokemonpa">
    <table class="table table-striped" style="background: #ddeeff">
      <tr>
        <td rowspan="4"><img :src="urlrup" v-if="this.pkd.lek" style="width: 100%"></td>
        <td>{{ pkd.lek }}</td>
        <td>{{ pkd.namae_th }} ({{ pkd.namae_en }})</td>
      </tr>
      <tr>
        <td>LV: <b>{{ pkd.ori_lv }}</b></td>
        <td>
          <span style="font-size: 20px" :style="{ color: 'rgb' + ma.sithat[pkd.ele1] }">{{ pkd.ele1 }}</span>
          <span :style="{ color: 'rgb' + ma.sithat[pkd.ele2] }"> {{ pkd.ele2 }}</span>
        </td>
      </tr>
      <tr>
        <td>SPD: <b style="font-size: 22px">{{ pkd.ori_spd }}</b><span style="font-size: 12px">　{{ parseInt(pkd.ori_spd * 1.25) }}　{{ parseInt(pkd.ori_spd * 1.3) }}</span></td>
        <td>DEF: <b style="font-size: 22px">{{ pkd.ori_def }}</b><span style="font-size: 12px">　{{ parseInt(pkd.ori_def * 1.25) }}　{{ parseInt(pkd.ori_def * 1.3) }}</span></td>
      </tr>
      <tr>
        <td>HP</td>
        <td>
          <input v-model="pk.hp" style="width: 60px"> / {{ pkd.ori_lv }}
          <span v-for="i in 3"><input v-model="khachomti[i - 1]" style="background: #FFBB99; width: 60px">
            <button @click="donchomti(i - 1)" class="btn-sm" style="background: #FF6644"><b>ลด HP</b></button></span><br>
          <button @click="pk.hp = 0" class="btn-sm" style="height: 30px; background: #C00000; font-size: 12px;">0</button>
          <button @click="pk.hp -= 1" class="btn-sm"
            style="height: 30px; background: #FF66FF; font-size: 12px; padding: 0px; min-width: 40px;">-1</button>
          <button v-for="k in [2, 3, 5, 10]" @click="pk.hp -= parseInt(pkd.ori_lv * k / 100)" class="btn-sm"
            style="height: 30px; background: #FF6644; font-size: 12px; padding: 0px; min-width: 40px;">-{{ k }}%</button>
          <button v-for="k in [2.5, 5, 10, 30]" @click="pk.hp += parseInt(pkd.ori_lv * k / 100)" class="btn-sm"
            style="height: 30px; background: #66FF66; font-size: 12px; padding: 0px; min-width: 40px;">+{{ k }}%</button>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div style="height:30px; width:100%; background:#666">
            <div style="height:30px;" :style="{ width: khanathp, background: sihp }">
            </div>
          </div>
        </td>
        <td>
          <sadaengabstat />
          <input v-model="khaabstat" style="width: 40px">
          <button @click="khaabstat = parseInt(khaabstat * 1) + 10" class="btn-sm">ทานเอ็นโดะ</button>
        </td>
      </tr>
    </table>

    <table border="2">
      <tr>
        <th>สกิล</th>
        <th>ธาตุ</th>
        <th>ACC</th>
        <th>ATK</th>
        <th>PP</th>
        <th>type</th>
        <th>condi</th>
        <th>sticked</th>
        <th>effect</th>
      </tr>
      <tr v-for="(s, i) in skill">
        <td>{{ s.sk.namae_th }} ({{ s.sk.namae_en }})</td>
        <td style="font-size: 8px" :style="{ color: 'rgb' + ma.sithat[ele(s.sk.skid)] }">{{ ele(s.sk.skid) }}</td>
        <td>{{ s.sk.acc }}</td>
        <td>{{ ma.haatk(s.sk.ori_atk, pkd.ori_lv, 0) }}</td>
        <td>
          <input v-model="pp[i]" style="width: 30px">
          {{ s.sk.ori_pp }}
          <button @click="phoempp(i)">+</button>
        </td>
        <td style="font-size: 10px">{{ s.sk.sktype }}</td>
        <td style="font-size: 10px">{{ s.sk.skcon }}</td>
        <td style="font-size: 10px">{{ s.sk.sticked }}</td>
        <td>{{ s.sk.effect }}</td>
      </tr>
    </table>
    <div>
      <button @click="chappk" class="btn btn-info">จับโปเกมอน</button>
      ชื่อเล่น <input v-model="pk.namaelen" style="width: 120px">
      ใช้
      <select v-model="pk.ball">
        <option v-for="b in $store.state.listball" :value="b.iid" v-if="b.iid in $store.state.ballmi">{{ b.namae_th }}
        </option>
      </select>
    </div>
    <button @click="yangsudai = 1" v-if="!yangsudai"
      style="position: fixed; right: 100px; top: 100px; background: #fba; border: solid 5px #385; padding: 10px; font-size: 30px">
      <img :src="ma.ror('rup/ie/battle_reminder.jpg')" style="width: 221px">
    </button>
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
import sadaengabstat from '@/components/sadaengabstat.vue'
const axios = require('axios');
import TweenLite from 'gsap'

var lek_thep = [144, 145, 146, 150, 151, 243, 244, 245, 249, 250, 251, 252, 258, 259, 260, 261, 262, 263, 264, 265, 266, 387]

export default {
  name: 'Pokemonpa',
  components: {
    sadaengabstat
  },
  data: function () {
    return {
      ma: ma,
      pkd: {},
      n_pk_mi: 0,
      khachomti: [0, 0, 0],
      skill: [],
      pp: [],
      khaabstat: 0,
      twhp: 0,
      sadaengtarangthat: 0,
      sadaengvirus: 0,
      yangsudai: 1
    }
  },
  props: {
    pk: Object,
  },
  methods: {
    saikhomun: function () {
      this.pp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      axios.get(ma.ror('pkdata/' + this.pk.pk_lek))
        .then(data => {
          Vue.set(this, "pkd", data.data)
          Vue.set(this.pk, "hp", this.pkd.ori_lv)
          if (lek_thep.includes(parseInt(this.pk.pk_lek))) var n_skill_sungsut = 100
          else var n_skill_sungsut = 6
          ma.haskillpk(this, this.pk.pk_lek, this.pkd.ori_lv, n_skill_sungsut)
        })
    },
    phoempp: function (i) {
      Vue.set(this.pp, i, String(parseInt(this.pp[i]) + 1))
    },
    donchomti: function (i) {
      this.pk.hp -= this.khachomti[i]
    },
    chappk: function () {
      this.$emit("chappk", this.pk, this.pkd, this.skill)
    },
    ele: function (skid) {
      return ma.skidtoel(skid)
    }
  },
  computed: {
    urlrup: function () {
      return ma.ror("rup/pokemon/gif/" + ('00' + this.pkd.lek).slice(-3) + ".gif")
    },
    sihp: function () {
      var r = parseInt((1 - this.twhp / this.pkd.ori_lv) * 15).toString(16)
      var g = parseInt(this.twhp / this.pkd.ori_lv * 12).toString(16)
      return "#" + r + g + "0"
    },
    khanathp: function () {
      return this.twhp / this.pkd.ori_lv * 100 + "%"
    },
    def: function () {
      return ma.hadef(this.pkd.ori_def, this.pkd.ori_lv, this.pkd.ori_lv, this.pkd.rise_def, 0)
    },
    spd: function () {
      return ma.haspd(this.pkd.ori_spd, this.pkd.ori_lv, this.pkd.ori_lv, this.pkd.rise_spd, 0)
    }
  },
  watch: {
    'pk.hp': function (hpmai, hpdoem) {
      if (hpmai < 0) this.pk.hp = 0
      if (hpmai <= 0) this.yangsudai = 0
      if (hpmai > this.pkd.ori_lv) this.pk.hp = this.pkd.ori_lv
      if (parseInt(hpmai) != hpmai) this.pk.hp = hpdoem
      TweenLite.to(this.$data, 0.5, { twhp: hpmai });
    }
  },
  created: function () {
    this.saikhomun()
    this.pk.ball = "RBall"
  }
}
</script>

<style scoped lang="scss"></style>
