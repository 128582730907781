<template>
  <div class="supokemonpa">
    
    <div v-if="!sulaitua">
      <h3>โปเกมอนป่า</h3>
      หมายเลข <input v-model="pk_lek" style="width: 100px">
      <button @click="roemsutuadiao" class="btn btn-warning" style="width: 120px">เริ่มสู้</button><br><br>
      <button @click="sulaitua=1" class="btn btn-warning" style="width: 300px">สู้หลายตัว</button>
    </div>
    <div v-if="sulaitua">
      <button @click="sulaitua=0" class="btn btn-warning" style="width: 300px">สู้ตัวเดียว</button>
      <br><br>
      <table>
        <tr v-for="(_,i) in pk_list">
          <td>หมายเลข <input v-model="pk_list[i][0]" style="width: 80px"></td>
          <td>จำนวน <input v-model="pk_list[i][1]" style="width: 80px"> ตัว</td>
        </tr>
        <tr>
          <td><button @click="pk_list.push(['',''])" class="btn btn-warning">เพิ่ม</button></td>
          <td><button @click="roemsu" class="btn btn-warning" style="width: 200px">เริ่มสู้</button></td>
        </tr>
      </table>
    </div>
    <transition name="roemsu">
      <div v-if="pokemon.length">
        <pokemonpa v-for="(pk,i) in pokemon" v-show="i>=supailaeo" :pk="pk" @chappk="chappk"/>
      </div>
    </transition>
    <div style="position: fixed; right: 10px; bottom: 10px">
      <button @click="sadaengtarangthat=true" class="btn btn-info">ตารางธาตุ</button><br><br>
      <button @click="sadaengvirus=true" class="btn btn-info">ตารางไวรัส</button>
    </div>
    <img v-if="sadaengtarangthat" :src="ma.ror('rup/ie/element.jpg')" @click="sadaengtarangthat=false" style="position: fixed; left: 10px; top: 10px; height: 70%">
    <img v-if="sadaengvirus" :src="ma.ror('rup/ie/virus.jpg')" @click="sadaengvirus=false" style="position: fixed; left: 10px; top: 10px; height: 70%">
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
import pokemonpa from '@/components/pokemonpa.vue'
const axios = require('axios');
import TweenLite from 'gsap'

var lek_thep = [144,145,146,150,151,243,244,245,249,250,251,252,258,259,260,261,262,263,264,265,266,387]

export default {
  name: 'Pokemonpa',
  components: {
    pokemonpa
  },
  data: function() {
    return {
      ma: ma,
      sulaitua: 0,
      supailaeo: 0,
      pk_lek: "",
      pk_list: [["",""]],
      pokemon: [],
      n_pk_mi: 0,
      sadaengtarangthat: 0,
      sadaengvirus: 0
    }
  },
  props: {

  },
  methods: {
    saikhomun: function() {
      axios.get(ma.ror('pkpc'),{params: {pl: this.$route.params.phulen, aokhaelak: 1}})
        .then(data => {
          Vue.set(this,"n_pk_mi",data.data.length)
        }
      )
    },
    choelaeo: function(lek) {
      axios.get(ma.ror('phulen/'+this.$route.params.phulen),{params: {lok: this.$route.params.lok,suan: "pkhen"}})
        .then(data => {
          var pkhen = data.data.pkhen
          if(!(parseInt(lek) in pkhen)) pkhen.push(parseInt(lek))          
          axios.patch(ma.ror('phulen/'+this.$route.params.phulen),{
            lok: this.$route.params.lok,
            suan: "pkhen",
            khomun: pkhen
          }).then(data => {})
        }
      )
    },
    chaplaeo: function(lek) {
      axios.get(ma.ror('phulen/'+this.$route.params.phulen),{params: {lok: this.$route.params.lok,suan: "pkmi"}})
        .then(data => {
          var pkmi = data.data.pkmi
          if(!(parseInt(lek) in pkmi)) pkmi.push(parseInt(lek))          
          axios.patch(ma.ror('phulen/'+this.$route.params.phulen),{
            lok: this.$route.params.lok,
            suan: "pkmi",
            khomun: pkmi
          }).then(data => {})
        }
      )
    },
    roemsutuadiao: function() {
      this.pk_list = [[this.pk_lek,1]]
      this.roemsu()
    },
    roemsu: function() {
      this.supailaeo = this.pokemon.length
      var i = 0
      while(i<this.pk_list.length) {
        var pk_lek = this.pk_list[i][0]
        var n = this.pk_list[i][1]
        if((pk_lek>0)&(n>0)) {
          var j = 0
          while(j<n) {
            this.pokemon.push({pk_lek: pk_lek})
            this.choelaeo(pk_lek)
            j += 1
          }
        }
        i += 1
      }
    },
    ha_slot: function() {
      if(this.n_pk_mi<6) {
        this.n_pk_mi += 1
        return this.n_pk_mi
      }
      else return 0
    },
    chappk: function(pk,pkd,skill) {
      pk.seq = this.$route.params.phulen+(Math.floor(Math.random()*10000000000)+1)
      var seq = pk.seq
      pk.lv = pkd.ori_lv
      pk.def_plus = 0
      pk.spd_plus = 0
      pk.atk_plus = 0
      pk.hp_plus = 0
      pk.abstat = ""
      pk.slot = this.ha_slot()
      pk.item = ""
      pk.pl_namae = this.$route.params.phulen

      this.chaplaeo(pk.pk_lek)

      this.$store.state.ballmi[pk.ball] -= 1
      if(this.$store.state.ballmi[pk.ball]==0) delete this.$store.state.ballmi[pk.ball]
      axios.patch(ma.ror('phulen/'+this.$route.params.phulen),{
        lok: this.$route.params.lok,
        suan: "pkball",
        khomun: this.$store.state.ballmi
      }).then(data => {
          Vue.set(this.$store.state,"ballmi",data.data)
        }
      )

      axios.post(ma.ror("pkpc"),{khomun: pk})
        .then(data => {
          this.banthuek_sk(pk,skill,skill.length-1)
      })

      ind = this.$store.state.nathilueaklaeo.indexOf("duball")
      if(ind!=-1) this.$store.state.nathilueaklaeo.splice(ind,1)
      var ind = this.$store.state.nathilueaklaeo.indexOf("pkpc")
      if(ind!=-1) this.$store.state.nathilueaklaeo.splice(ind,1)
      this.$emit("plianna","pokedex")
      this.$store.state.nathilueaklaeo.push("pokemonlak")
      ind = this.$store.state.nathilueaklaeo.indexOf("pokemonpa")
      if(ind!=-1) this.$store.state.nathilueaklaeo.splice(ind,1)
    },
    banthuek_sk: function(pk,skill,i) {
      var khomun = {
        pkpc_seq: pk.seq,
        sk_id: skill[i].sk.skid,
        od: i
      }
      axios.post(ma.ror("sktopkpc"),{khomun: khomun})
        .then(data => {
          if(i>0) this.banthuek_sk(pk,skill,i-1)
      })
    }
  },
  computed: {
    urlrup: function() {
      return ma.ror("rup/pokemon/"+this.pkd.lek+".png")
    },
    sihp: function() {
      var r = parseInt((1-this.twhp/this.pkd.ori_lv)*15).toString(16)
      var g = parseInt(this.twhp/this.pkd.ori_lv*12).toString(16)
      return "#"+r+g+"0"
    },
    khanathp: function() {
      return this.twhp/this.pkd.ori_lv*100+"%"
    },
    def: function() {
      return ma.hadef(this.pkd.ori_def, this.pkd.ori_lv, this.pkd.ori_lv, this.pkd.rise_def, 0)
    },
    spd: function() {
      return ma.haspd(this.pkd.ori_spd, this.pkd.ori_lv, this.pkd.ori_lv, this.pkd.rise_spd, 0)
    }
  },
  watch: {
    'pk.hp': function(hpmai,hpdoem) {
      if(hpmai<0) this.pk.hp = 0
      if(hpmai>this.pkd.ori_lv) this.pk.hp = this.pkd.ori_lv
      if(parseInt(hpmai)!=hpmai) this.pk.hp = hpdoem
      TweenLite.to(this.$data, 0.5, {twhp: hpmai});
    }
  },
  created: function() {
    this.saikhomun()
  }
}
</script>

<style scoped lang="scss">
.roemsu-enter-active {
  transition: opacity 2s;
}
.roemsu-enter {
  opacity: 0;
}
</style>
