<template>
  <div class="trainer">
    <h4 style="padding-top: 10px">สู้เทรนเนอร์ <span v-if="trainerthilueak">{{trainerthilueak}}</span></h4>
    <div v-if="trainerthilueak">
      <button @click="roemsulaeo=1" v-if="khomuntrainer" style="font-size: 21px; font-weight: bold">{{khomuntrainer.pn}} vs {{khomuntrainer.pn}}</button><br><br>
      <button @click="trainerthilueak=''; roemsulaeo=0" v-if="khomuntrainer" style="font-size: 21px; font-weight: bold">เลิกสู้</button>
      <table>
        <tr>
          <td v-for="(p,i) in khomuntrainer.pokemon" class="kropball" :style="{background: bgball, 'background-size': '100px'}">
            <span v-if="roemsulaeo&$store.state.trpk_henlaeo[i]" style="font-size: 36px;">{{p.lv}}</span>
          </td>
        </tr>
      </table>
      <transition name="roemsu">
        <div v-if="roemsulaeo">
          <table v-if="$store.getters.n_sudai">
            <tr v-for="(pk,i) in khomuntrainer.pokemon">
              <td>
                <transition name="trainerpk">
                  <trainerpk :pk="pk" :ipk="i" @henlaeo="henlaeo" v-show="$store.state.trpk_suyu[i]" />
                </transition>
              </td>
            </tr>
          </table>
          <div v-else style="font-size: 20px">
            <button @click="chanalaeo" class="btn btn-success">ชนะแล้ว</button><br>
            ได้เงิน <span style="font-size: 24px">{{ngoen}}</span>
            <span v-if="item">
              <br>ได้ไอเท็ม
              <button v-for="i in item.split(',')" style="margin: 0px; padding: 0px; width: 50px; height: 50px; font-size: 6px" :style="{background: 'url('+ma.ror('rup/item/'+i.trim()+'.jpg')+')'}"><span style="opacity: 0.5; color: blue"></span></button>
            </span>
            <div v-if="khomuntrainer.balo!=''">{{trainerthilueak}}: {{khomuntrainer.balo}}</div>
          </div>
        </div>
      </transition>
    </div>
    <div v-else style="text-align: left;">

      <div>
        <button v-for="tn in laotrainer.lek" @click="lueaktrainer(tn.namae)" class="pumtrainer" :class="{balolaeo: balo.includes(tn.namae)}">{{tn.namae}}</button>
      </div>
      <br><br>
      <div>
        <button v-for="tn in laotrainer.uenuen" @click="lueaktrainer(tn.namae)" class="pumtrainer" :class="{balolaeo: balo.includes(tn.namae)}">{{tn.namae}}</button>
      </div>
      <br><br>
      <div v-for="i in '1234'">
        Re{{i}}<br>
        <button v-for="tn in laotrainer['Re'+i]" @click="lueaktrainer(tn.namae)" class="pumtrainer">{{tn.namae}}</button>
      <br><br>
      </div>
      <br>
      <button v-for="tn in laotrainer['GYM']" @click="lueaktrainer(tn.namae)" class="pumtrainer" style="width: 160px;">{{tn.namae}}</button>
      <br><br>
      <button v-for="tn in laotrainer['R']" @click="lueaktrainer(tn.namae)" class="pumtrainer">{{tn.namae}}</button>
      <br><br>
      <button v-for="tn in laotrainer['dao']" @click="lueaktrainer(tn.namae)" class="pumtrainer">{{tn.namae}}</button>
      <br><br>
      <button v-for="tn in laotrainer['wonglep']" @click="lueaktrainer(tn.namae)" class="pumtrainer">{{tn.namae}}</button>
      <br><br>
      <div v-for="si in ['Red','Green','Blue','Yellow','Gold','Silver','Crystal']">
        <img :src="ma.ror('rup/rival/'+si+'.jpg')">
        <button v-for="tn in laotrainer[si]" @click="lueaktrainer(tn.namae)" class="pumtrainer">{{tn.namae}}</button>
      </div>
    </div>
    <div style="position: fixed; right: 10px; bottom: 10px">
      <button @click="sadaengtarangthat=true" class="btn btn-info">ตารางธาตุ</button><br><br>
      <button @click="sadaengvirus=true" class="btn btn-info">ตารางไวรัส</button>
    </div>
    <img v-if="sadaengtarangthat" :src="ma.ror('rup/ie/element.jpg')" @click="sadaengtarangthat=false" style="position: fixed; left: 10px; top: 10px; height: 70%">
    <img v-if="sadaengvirus" :src="ma.ror('rup/ie/virus.jpg')" @click="sadaengvirus=false" style="position: fixed; left: 10px; top: 10px; height: 70%">
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
import trainerpk from '@/components/trainerpk.vue'
const axios = require('axios');

export default {
  name: 'Trainer',
  components: {
    trainerpk
  },
  data: function() {
    return {
      ma: ma,
      laotrainer: [],
      trainerthilueak: "",
      khomuntrainer: "",
      bgball: "url('"+ma.ror('rup/ball/RBallT.png')+"')",
      sadaengvirus: false,
      sadaengtarangthat: false,
      roemsulaeo: false,
      item: "",
      ngoen: 0,
      pkhen: [],
      balo: []
    }
  },
  props: {

  },
  methods: {
    saikhomun: function() {
      axios.get(ma.ror('trainer'), {
          params: {"sel": "namae"}
        })
        .then(data => {
          var laotrainer = {
            lek: [],
            R: [],
            Re1: [],
            Re2: [],
            Re3: [],
            Re4: [],
            GYM: [],
            dao: [],
            wonglep: [],
            Red: [],
            Green: [],
            Blue: [],
            Yellow: [],
            Gold: [],
            Silver: [],
            Crystal: [],
            uenuen: []
          }
          var i = 0
          while(i<data.data.length) {
            var tr = data.data[i]
            if(tr.namae.slice(0,3)=="Re1") laotrainer.Re1.push(tr)
            else if(tr.namae.slice(0,3)=="Re2") laotrainer.Re2.push(tr)
            else if(tr.namae.slice(0,3)=="Re3") laotrainer.Re3.push(tr)
            else if(tr.namae.slice(0,3)=="Re4") laotrainer.Re4.push(tr)
            else if(tr.namae[0]=="*") laotrainer.dao.push(tr)
            else if(tr.namae[0]=="(") laotrainer.wonglep.push(tr)
            else if(tr.namae.includes("Red")) laotrainer.Red.push(tr)
            else if(tr.namae.includes("Green")) laotrainer.Green.push(tr)
            else if(tr.namae.includes("Blue")) laotrainer.Blue.push(tr)
            else if(tr.namae.includes("Yellow")) laotrainer.Yellow.push(tr)
            else if(tr.namae.includes("Gold")) laotrainer.Gold.push(tr)
            else if(tr.namae.includes("Silver")) laotrainer.Silver.push(tr)
            else if(tr.namae.includes("Crystal")) laotrainer.Crystal.push(tr)
            else if(tr.namae.slice(0,3)=="GYM") laotrainer.GYM.push(tr)
            else if(tr.namae[0]=="R") laotrainer.R.push(tr)
            else if(tr.namae==parseInt(tr.namae)) laotrainer.lek.push(tr)
            else laotrainer.uenuen.push(tr)
            i += 1
          }
          Vue.set(this,"laotrainer",laotrainer)
        }
      )

      axios.get(ma.ror('lok/'+this.$route.params.lok),{params: {suan: "balo"}})
        .then(data => {
          this.balo = data.data.balo
        }
      )

      axios.get(ma.ror('phulen/'+this.$route.params.phulen),{params: {lok: this.$route.params.lok,suan: "pkhen"}})
        .then(data => {
          this.pkhen = data.data.pkhen
        }
      )
    },
    lueaktrainer: function(tn) {
      this.trainerthilueak = tn
      this.$store.state.trpk = []
      this.$store.state.trpk_suyu = []
      this.$store.state.trpk_henlaeo = []
      this.$store.state.trpk_sudai = []
      axios.get(ma.ror("trainer/"+tn),{params: {aopk: 1}})
        .then(data => {
          Vue.set(this,"khomuntrainer",data.data)
          this.$store.state.n_trpk = this.khomuntrainer.pokemon.length
          var i = 0
          while(i<this.$store.state.n_trpk) {
            this.$store.state.trpk.push(this.khomuntrainer.pokemon[i])
            if(i<this.khomuntrainer.pn) {
              this.$store.state.trpk_suyu.push(true)
              this.$store.state.trpk_henlaeo.push(true)
              this.henlaeo(i)
            }
            else {
              this.$store.state.trpk_suyu.push(false)
              this.$store.state.trpk_henlaeo.push(false)
            }
            this.$store.state.trpk_sudai.push(true)
            i++
          }
          this.item = this.khomuntrainer.item
          this.ngoen = this.khomuntrainer.money
      })
    },
    chanalaeo: function() {
      this.balo.push(this.trainerthilueak)
      axios.patch(ma.ror("lok/"+this.$route.params.lok),{
        suan: "balo",
        khomun: this.balo
      }).then(data => {
          Vue.set(this,"balo",data.data)
        }
      )

      this.trainerthilueak = ""
      this.roemsulaeo = 0
      var url = ma.ror("phulen/"+this.$route.params.phulen)
      var params = {
        lok: this.$route.params.lok,
        suan: "item,uenuen"
      }
      axios.get(url,{params: params})
        .then(data => {
          var uenuen = data.data.uenuen
          uenuen.เงิน += this.ngoen
          axios.patch(ma.ror("phulen/"+this.$route.params.phulen),{
            lok: this.$route.params.lok,
            suan: "uenuen",
            khomun: uenuen
          }).then(data => {
              console.log(data.data)
            }
          )

          if(this.item) {
            var itemmi = data.data.item
            var itemthidai = this.item.split(" ")
            var i = 0
            while(i<itemthidai.length) {
              var it = itemthidai[i]
              if(it in itemmi) itemmi[it] = itemmi[it]+1
              else itemmi[it] = 1
              i += 1
            }
            axios.patch(ma.ror("phulen/"+this.$route.params.phulen),{
              lok: this.$route.params.lok,
              suan: "item",
              khomun: itemmi
            }).then(data => {
                console.log(data.data)
              }
            )
          }
        }
      )
    },
    henlaeo: function(i) {
      var lek = this.khomuntrainer.pokemon[i].d.lek
      if(!(this.pkhen.includes(lek))) {
        this.pkhen.push(lek)
        axios.patch(ma.ror('phulen/'+this.$route.params.phulen),{
          lok: this.$route.params.lok,
          suan: "pkhen",
          khomun: this.pkhen
        }).then(data => {console.log(data.data)})
      }
    }
  },
  computed: {

  },
  created: function() {
    this.saikhomun()
  }
}
</script>

<style scoped lang="scss">
.kropball{
  width: 100px;
  height: 100px;
  font-size: 50px;
  vertical-align: top;
  background-repeat: no-repeat;
}
.roemsu-enter-active, .roemsu-leave-active {
  transition: opacity .5s;
}
.roemsu-enter, .roemsu-leave-to {
  opacity: 0;
}

.trainerpk-enter-active, .trainerpk-leave-active {
  transition: opacity 1s;
}
.trainerpk-enter, .trainerpk-leave-to {
  opacity: 0;
}
.pumtrainer {
  width: 100px;
  height: 50px;
  margin: 5px;
}
.balolaeo {
  opacity: 0.3;
}
</style>
