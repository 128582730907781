<template>
  <div class="nalak">
    <table>
      <tr>
        <td style="text-align: left; padding-top: 10px">
          <h5>{{ phulen }} ใน {{ lok }}</h5>
        </td>
        <td style="text-align: right">
          <button class="btn" @click="klapnalak"
            style="padding: 5px; font-size: 10px; background: grey">เลือกผู้เล่นใหม่</button>
        </td>
      </tr>
      <tr>
        <td style="text-align: left;" colspan="2">
          <button v-for="na in [
            ['supokemonpa', 'โปเกมอนป่า'],
            ['trainer', 'เทรนเนอร์'],
            ['sadaengskill', 'สกิล'],
            ['pokedex', 'pokedex']
          ]" @click="lueakna(na[0])" :class="classthaeplueak(na[0])">
            {{ na[1] }}
          </button>
          <button @click="sadaengnote = !sadaengnote" class="btn"
            :class="{ 'btn-warning': !sadaengnote, 'btn-success': sadaengnote }">note</button>
          <div v-show="sadaengnote">
            <button @click="banthueknote" class="btn btn-danger"
              style="padding: 1px; font-size: 22px; font-weight: 800; width: 160px; height: 50px">บันทึกโน้ต</button>
            <textarea v-model='$store.state.note[0]' style="width: 100%; height: 100px"></textarea><br>
            <textarea v-model='$store.state.note[1]' style="width: 100%; height: 100px"></textarea><br>
            <textarea v-model='$store.state.note[2]' style="width: 100%; height: 100px"></textarea><br>
            <textarea v-model='$store.state.note[3]' style="width: 100%; height: 200px"></textarea>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <nalueakraek @lueakna="lueakna" v-show="nathilueak == 'nalueakraek'" />
          <pokedex v-show="nathilueak == 'pokedex'" v-if="$store.state.nathilueaklaeo.includes('pokedex')" />
          <trainer v-show="nathilueak == 'trainer'" v-if="$store.state.nathilueaklaeo.includes('trainer')" />
          <supokemonpa v-show="nathilueak == 'supokemonpa'" v-if="$store.state.nathilueaklaeo.includes('supokemonpa')"
            @plianna="lueakna" />
          <sadaengskill v-show="nathilueak == 'sadaengskill'" v-if="$store.state.nathilueaklaeo.includes('sadaengskill')" />
          <rankha v-show="nathilueak == 'rankha'" v-if="$store.state.nathilueaklaeo.includes('rankha')" />
        </td>
      </tr>
    </table>

  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
import nalueakraek from '@/components/nalueakraek.vue'
import pokedex from '@/components/pokedex.vue'
import trainer from '@/components/trainer.vue'
import supokemonpa from '@/components/supokemonpa.vue'
import rankha from '@/components/rankha.vue'
import sadaengskill from '@/components/sadaengskill.vue'
const axios = require('axios');

export default {
  name: 'Nalak',
  components: {
    nalueakraek,
    pokedex,
    trainer,
    supokemonpa,
    rankha,
    sadaengskill
  },
  props: {
    lok: String,
    phulen: String
  },
  data: function () {
    return {
      ma: ma,
      nathilueak: "nalueakraek",
      sadaengnote: 0
    }
  },
  methods: {
    saikhomun: function () {
      axios.get(ma.ror('itemdata'), { params: { col: '{"icat": "Ball"}' } })
        .then(data => {
          Vue.set(this.$store.state, "listball", data.data)
        }
        )

      axios.get(ma.ror('itemdata'), { params: { col: '{"icat": "Other"}' } })
        .then(data => {
          Vue.set(this.$store.state, "listitem", data.data)
          var i = 0
          while (i < data.data.length) {
            this.$store.state.index_item[data.data[i].iid] = i
            i++
          }
        }
        )

      axios.get(ma.ror('phulen/' + this.$route.params.phulen), {
        params: {
          lok: this.$route.params.lok,
          suan: "pkball,heavy,mc,item,uenuen,badge,kitem,card,chip,note,mastery,quest,godskill,thanakhan"
        }
      })
        .then(data => {
          Vue.set(this.$store.state, "ballmi", data.data.pkball)
          Vue.set(this.$store.state, "heavymi", data.data.heavy)
          Vue.set(this.$store.state, "mcmi", data.data.mc)
          Vue.set(this.$store.state, "itemmi", data.data.item)
          Vue.set(this.$store.state, "uenuenmi", data.data.uenuen)
          Vue.set(this.$store.state, "badgemi", data.data.badge)
          Vue.set(this.$store.state, "cardmi", data.data.card)
          Vue.set(this.$store.state, "kitemmi", data.data.kitem)
          Vue.set(this.$store.state, "chipmi", data.data.chip)
          Vue.set(this.$store.state, "note", data.data.note)
          Vue.set(this.$store.state, "mastery", data.data.mastery)
          Vue.set(this.$store.state, "quest", data.data.quest)
          Vue.set(this.$store.state, "godskill", data.data.godskill)
          Vue.set(this.$store.state, "thanakhan", data.data.thanakhan)
        })
    },
    lueakna: function (na) {
      this.nathilueak = na
      if (!this.$store.state.nathilueaklaeo.includes(na)) this.$store.state.nathilueaklaeo.push(na)
    },
    classthaeplueak: function (na) {
      return {
        "btn": true,
        "btn-success": this.nathilueak == na,
        "btn-info": this.nathilueak != na,
      }
    },
    klapnalak: function () {
      window.location.href = '/'
    },
    banthueknote: function () {
      axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "note",
        khomun: this.$store.state.note
      }).then(data => {
        Vue.set(this.$store.state, "note", data.data)
      }
      )
    }
  },
  computed: {
  },
  created: function () {
    this.$store.state.nathilueaklaeo = []
    this.lueakna("pokedex")
    this.saikhomun()
  }
}
</script>

<style scoped lang="scss">
.btn-success {
  font-weight: bold;
}
</style>

<style lang="scss">
.pumbanthuek {
  position: fixed;
  top: 40px;
  right: 15%;
  width: 200px;
  height: 60px
}
</style>