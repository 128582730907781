<template>
  <div class="nalueakraek">
    <table border="1" class="table">
      <tr>
        <td @click="$emit('lueakna','pokemonpa')">โปเกมอนป่า</td>
        <td @click="$emit('lueakna','trainer')">เทรนเนอร์</td>
        <td rowspan="2" @click="$emit('lueakna','pokedex')">pokedex</td></tr>
      <tr>
        <td @click="$emit('lueakna','rankha')">ร้านค้า</td>
      </tr>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';
const axios = require('axios');

export default {
  name: 'Nalueakraek',
  components: {
    
  },
  data: function() {
    return {

    }
  },
  props: {

  },
  methods: {

  },
  computed: {

  },
  created: function() {
    
  }
}
</script>

<style scoped lang="scss">
.nalueakraek td{
  height:200px;
  vertical-align: middle;
  font-size: 22px;
}
</style>
