<template>
  <div class="questmas" style="width: 900px">
    <button @click="banthuek" class="btn btn-danger pumbanthuek"
      style="padding: 1px; font-size: 22px; font-weight: 800">save</button>
    <div style="background-color: #c4e0b4; padding: 10px;">
      <div style="font-size: 26px;">Quest</div>
      <table style=" width: 100%; text-align: left;">
        <template v-for="(chuequest, i) in ['Battle', 'Explorer', 'Item']">
          <tr>
            <td style="width: 80px;">
              <div style="background-color: #FFFFFF; padding: 5px;">{{ chuequest }}</div>
            </td>
          </tr>
          <tr v-for="j in 3">
            <td style="padding-left: 5px;">Quest {{ j }} :</td>
            <td>
              <input v-model="$store.state.quest[i][j - 1]" style="width: 100%">
            </td>
          </tr>
        </template>
      </table>
    </div>
    <div style="background-color: #FF9999; padding: 10px;">
      <div style="font-size: 26px;">God Skill</div>
      <table style=" width: 100%; text-align: left;">
        <template v-for="(lisgs, rank) in $store.state.godskill">
          <tr>
            <td style="width: 80px;">
              <div style="background-color: #FFFFFF; padding: 5px;">Rank {{ rank }}</div>
            </td>
            <td>
              {{ {
                "E": "Turn End 10 | 10 | 20 ...", "D": "Turn End 10 | 20 | 10 ...", "C": "Turn End 20 | 20 | 20 ...", "B":
                  "Turn End 30 | 20 | 20 ...", "A": "Turn End 30 | 20 | 30 ...", "SA": "Turn End 30 | 30 | 30 ...", "UA":
                  "Turn End 40 | 40 | 40 ..."
              }[rank] }}
            </td>
          </tr>
          <tr v-for="(gs, lek) in lisgs" v-if="lek <= 266 || parseInt(lek) <= { 3: 387, 4: 467, 5: 623, 6: 695 }[$store.state.uenuenmi.hengen]">
            <td>{{ pkdic[lek] }}:</td>
            <td style="width: 700px; text-align: right;">
              <input v-model="gs[0]" style="width: 98%;">
            </td>
            <td>
              <input v-model="gs[1]" style="width: 40px; text-align: right;"> /{{ {
                "E": 30, "D": 25, "C": 20, "B": 15, "A": 10, "SA": 5, "UA":
                  3
              }[rank] }}
            </td>
          </tr>
        </template>
      </table>
    </div>
    <!--<br>
    <table>
      <tr v-for="(chuemas, i) in chuemastery">
        <td :style="{ background: simastery[i] }">
          <h3>{{ chuemas[0] }}</h3>
          <table>
            <tr>
              <td v-for="(c, j) in chuemas[1]">
                <h5>{{ c }}</h5>
                <textarea v-model="$store.state.mastery[i][j][0]" style="width: 300px; height: 150px"></textarea>
                <textarea v-model="$store.state.mastery[i][j][1]" style="width: 300px; height: 30px"></textarea>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    -->
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
const axios = require('axios');

export default {
  name: 'Questmas',
  components: {
  },
  data: function () {
    return {
      pkdic: {},
      simastery: ["#FF9999", "#99FF99", "#9999FF"],
      chuemastery: [["Level mastery", ["Battle", "Wild", "Nursery"]], ["Time mastery", ["Explorer", "Speed", "Tactics"]], ["Money mastery", ["Negotiator", "Bargainer", "Price Hiker"]]],
    }
  },
  props: {
    cache: Array
  },
  methods: {
    saikhomun: function () {
      axios.get(ma.ror('pkdata'), {
        params: {
          "sel": "lek,namae_en"
        }
      })
        .then(data => {
          for (var p of data.data) {
            console.log(p)
            Vue.set(this.pkdic, p.lek, p.namae_en)
          }
        }
        )
    },
    banthuek: function () {
      /*axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "mastery",
        khomun: this.$store.state.mastery
      }).then(data => {
        Vue.set(this.$store.state, "mastery", data.data)
      }
      )*/

      axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "quest",
        khomun: this.$store.state.quest
      }).then(data => {
        Vue.set(this.$store.state, "quest", data.data)
      }
      )

      axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "godskill",
        khomun: this.$store.state.godskill
      }).then(data => {
        Vue.set(this.$store.state, "godskill", data.data)
      }
      )
    }
  },
  computed: {

  },
  created: function () {
    this.saikhomun()
  }
}
</script>

<style scoped lang="scss"></style>
