<template>
  <div style="width: 800px">
    <button @click="banthuek" class="btn btn-danger pumbanthuek"
      style="padding: 1px; font-size: 22px; font-weight: 800">save</button>
    <div>
      <h4>เหรียญตรา</h4>
      <table class="table">
        <tr class="badge">
          <td v-for="(b, i) in listbadge.slice(0, 8)" @click="phoemlopbadge(b)"
            style="padding: 0px; width: 100px; height: 130px">
            <button v-if="$store.state.badgemi.includes(b)" style="width: 100px; height: 100px"
              :style="{ background: 'url(' + ma.ror('rup/badge/' + b + '.jpg') + ')' }"></button>
            <button v-else style="width: 100px; height: 100px; background: black;"></button>
            <br>{{ b }}<br>{{ gym[i] }}
          </td>
        </tr>
        <tr class="badge">
          <td v-for="(b, i) in listbadge.slice(8, 16)" @click="phoemlopbadge(b)"
            style="padding: 0px; width: 100px; height: 130px">
            <button v-if="$store.state.badgemi.includes(b)" style="width: 100px; height: 100px"
              :style="{ background: 'url(' + ma.ror('rup/badge/' + b + '.jpg') + ')' }"></button>
            <button v-else style="width: 100px; height: 100px; background: black;"></button>
            <br>{{ b }}<br>{{ gym[i + 8] }}
          </td>
        </tr>
      </table>
      <h4>ไอเท็มสำคัญ</h4>
      <span v-for="(l, i) in league" @click="phoemlopbadge(l)">
        <button v-if="$store.state.badgemi.includes(l)" style="width: 200px; height: 200px">{{ l }}</button>
        <button v-else style="width: 200px; height: 200px; background: green;">{{ l }}</button>
      </span>
      <br>
      <br>
      <div style="width: 450px; text-align: left">
        การ์ด
        {{ $store.state.cardmi.length }}/<input v-model="$store.state.uenuenmi.n_card" style="width: 40px">
        <select v-model="cardlueak">
          <option v-for="c in listcard" v-if="!$store.state.cardmi.includes(c.iid)" :value="c.iid">{{ c.namae_th }}
          </option>
        </select>
        <br>
        <span v-for="c in $store.state.cardmi" style="margin: 0px;">
          <button @click="lopcard(c)" style="width: 75px; height: 40px;"
            :style="{ background: 'url(' + ma.ror('rup/item/' + c + '.jpg') + ')' }"></button>
        </span>
        <span v-for="i in Math.max(0, $store.state.uenuenmi.n_card - $store.state.cardmi.length)" style="margin: 0px;">
          <button @click="phoemcard" style="width: 75px; height: 40px;"></button>
        </span>
      </div>
      <br>
      <div style="width: 450px; text-align: left">
        คีย์ไอเท็ม
        {{ $store.state.kitemmi.length }}/<input v-model="$store.state.uenuenmi.n_kitem" style="width: 40px">
        <select v-model="kitemlueak">
          <option v-for="k in listkitem" v-if="!$store.state.kitemmi.includes(k.iid)" :value="k.iid">{{ k.namae_th }}
          </option>
        </select>
        <br>
        <button v-for="k in $store.state.kitemmi" @click="lopkitem(k)" style="width: 75px; height: 75px;"
          :style="{ background: 'url(' + ma.ror('rup/item/' + k + '.jpg') + ')' }"></button>
        <button v-for="i in Math.max(0, $store.state.uenuenmi.n_kitem - $store.state.kitemmi.length)" @click="phoemkitem"
          style="width: 75px; height: 75px;"></button>
      </div>
      <br>
      <div>
        Chip
          <div>
            <button v-for="c in chipthangmot" @click="lueakchip(c + 'PP')"
              :class="{ chipthilueak: $store.state.chipmi.includes(c + 'PP') }"
              :style="{ width: ((c.length + 1) * 12) + 'px' }">PP</button>
          </div>
        <div>
          <button v-for="c in chipthangmot" @click="lueakchip(c + 'UP')"
            :class="{ chipthilueak: $store.state.chipmi.includes(c + 'UP') }"
            :style="{ width: ((c.length + 1) * 12) + 'px' }">UP</button>
        </div>
        <div>
          <button v-for="c in chipthangmot" @click="lueakchip(c)"
            :class="{ chipthilueak: $store.state.chipmi.includes(c) }" :style="{ width: ((c.length + 1) * 12) + 'px' }">{{ c
            }}</button>
        </div>
        <div style="position: relative; height: 246px">
          <div style="position: absolute; top: 0px; left: 0px" height="246">
            <img :src="ma.ror('rup/chip/Main.png')">
          </div>
          <div v-for="c in $store.state.chipmi" style="position: absolute; top: 0px; left: 0px;">
            <img v-if="!c.endsWith('P')" :src="ma.ror('rup/chip/' + c + '.png')" height="246">
          </div>
        </div>
      </div>
    </div>
    <button @click="saikhomunmai" class="btn btn-warning">ยกเลิกความเปลี่ยนแปลง</button>
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
const axios = require('axios');

var listbadge = [
  "GREEN", "GREY", "BLUE", "ORANGE", "GOLD", "RAINBOW", "PINK", "RED",
  "WING", "INSECT", "NORMAL", "GHOST", "ROCK", "STEEL", "ICE", "DRAGON"
]

var gym = [
  "Tokowa", "Nibi", "Hanada", "Cuchiba", "Yamabuki", "Tamamushi", "Sekijiku", "Guren",
  "Kikyu", "Hiwada", "Kokane", "Enju", "Tumba", "Asazaki", "Joji", "Futsuba"
]

var league = ["GS Ball", "Jouto league", "1st Kanto league", "2st Kanto league"]

export default {
  name: 'Badge',
  components: {

  },
  data: function () {
    return {
      ma: ma,
      listbadge: listbadge,
      gym: gym,
      league: league,
      listcard: [],
      listkitem: [],
      cardlueak: "",
      kitemlueak: "",
      chipthangmot: ["FLASH", "CUT", "SURF", "WATERFALL", "TIDE", "RUN", "FLY", "ROCKSMASH", "DIVE"],
    }
  },
  props: {

  },
  methods: {
    saikhomun: function () {
      axios.get(ma.ror('itemdata'), { params: { col: '{"icat": "Card"}' } })
        .then(data => {
          Vue.set(this, "listcard", data.data)
        }
        )
      axios.get(ma.ror('itemdata'), { params: { col: '{"icat": "Key Item"}' } })
        .then(data => {
          Vue.set(this, "listkitem", data.data)
        }
        )
    },
    saikhomunmai: function () {
      axios.get(ma.ror('phulen/' + this.$route.params.phulen), {
        params: {
          lok: this.$route.params.lok,
          suan: "badge,kitem,card,chip,uenuen"
        }
      })
        .then(data => {
          Vue.set(this.$store.state, "badgemi", data.data.badge)
          Vue.set(this.$store.state, "cardmi", data.data.card)
          Vue.set(this.$store.state, "kitemmi", data.data.kitem)
          Vue.set(this.$store.state, "chipmi", data.data.chip)
          Vue.set(this.$store.state, "uenuenmi", data.data.uenuen)
        })
    },
    phoemlopbadge: function (b) {
      if (this.$store.state.badgemi.includes(b)) this.$store.state.badgemi.splice(this.$store.state.badgemi.indexOf(b), 1)
      else this.$store.state.badgemi.push(b)
    },
    phoemkitem: function () {
      if (this.kitemlueak) this.$store.state.kitemmi.push(this.kitemlueak)
      this.kitemlueak = ""
    },
    lopkitem: function (kitem) {
      this.$store.state.kitemmi.splice(this.$store.state.kitemmi.indexOf(kitem), 1)
    },
    phoemcard: function () {
      if (this.cardlueak) this.$store.state.cardmi.push(this.cardlueak)
      this.cardlueak = ""
    },
    lopcard: function (card) {
      this.$store.state.cardmi.splice(this.$store.state.cardmi.indexOf(card), 1)
    },
    banthuek: function () {
      axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "badge",
        khomun: this.$store.state.badgemi
      }).then(data => {
        Vue.set(this.$store.state, "badgemi", data.data)
      }
      )

      axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "kitem",
        khomun: this.$store.state.kitemmi
      }).then(data => {
        Vue.set(this.$store.state, "kitemmi", data.data)
      }
      )

      axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "card",
        khomun: this.$store.state.cardmi
      }).then(data => {
        Vue.set(this.$store.state, "cardmi", data.data)
      }
      )

      axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "chip",
        khomun: this.$store.state.chipmi
      }).then(data => {
        Vue.set(this.$store.state, "chipmi", data.data)
      }
      )

      axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "uenuen",
        khomun: this.$store.state.uenuenmi
      }).then(data => {
        Vue.set(this.$store.state, "uenuenmi", data.data)
      }
      )
    },
    lueakchip: function (c) {
      if (this.$store.state.chipmi.includes(c)) {
        this.$store.state.chipmi.splice(this.$store.state.chipmi.indexOf(c), 1)
      }
      else {
        this.$store.state.chipmi.push(c)
      }
    }
  },
  computed: {
  },
  created: function () {
    this.saikhomun()
  }
}
</script>

<style scoped lang="scss">
.chipthilueak {
  background: #FFAAAA;
}</style>
