const axios = require("axios");

axios.defaults.baseURL;
var rorurl = "https://mpkapi.hinaboshi.com/";
//'http://localhost:3000/'
var ror = function(x) {
  return rorurl + x;
};

var thatthangmot = {
  PH: "PHYSICAL",
  FI: "FIRE",
  WA: "WATER",
  PL: "PLANT",
  EL: "ELECTRIC",
  FL: "FLYING",
  RO: "ROCK",
  GR: "GROUND",
  IN: "INSECT",
  NO: "NORMAL",
  DA: "DARK",
  PS: "PSYCHIC",
  PO: "POISON",
  IC: "ICE",
  HA: "HAPPY"
};

var skidtoel = function(x) {
  return thatthangmot[x.slice(0, 2)];
};

var sithat = {
  PHYSICAL: "(28,28,28)",
  FIRE: "(204,0,0)",
  WATER: "(0,51,204)",
  PLANT: "(0,128,0)",
  ELECTRIC: "(204,153,0)",
  FLYING: "(102,102,51)",
  ROCK: "(204,51,0)",
  GROUND: "(255,153,51)",
  INSECT: "(102,255,102)",
  NORMAL: "(51,153,102)",
  DARK: "(153,0,255)",
  PSYCHIC: "(153,102,0)",
  POISON: "(153,51,102)",
  ICE: "(51,153,255)",
  HAPPY: "(255,51,153)"
};

var haskdata = function(sktopk) {
  axios.get(ror("skdata/" + sktopk.sk_id)).then(data => {
    sktopk.sk = data.data;
  });
};

var haskillpk = function(t, lek, lv, n_ao) {
  axios
    .get(ror("sktopk"), {
      params: {
        lek: lek,
        sel: "sk_id,lv",
        lvmax: lv,
        aoskdata: 1,
        aorangkon: 1
      }
    })
    .then(data => {
      var skill = {};
      var d = data.data;
      d.sort(function(a, b) {
        return a.lv - b.lv;
      });
      /*for (var i in d) {
		haskdata(d[i])
		console.log(d)
	  }*/
      t.skill = d.slice(-n_ao);
    });
};

var chaektarang = function(khomun, kw) {
  var b = [[]];
  var ib = 0;
  var n = 0;
  khomun.forEach(function(k) {
    b[ib].push(k);
    n += 1;
    if (n >= kw) {
      ib += 1;
      n = 0;
      b.push([]);
    }
  });
  return b;
};

var chaektaranghash = function(khomun, kw) {
  var b = [[]];
  var ib = 0;
  var n = 0;
  Object.keys(khomun).forEach(function(k) {
    for (var i = 0; i < khomun[k]; i++) {
      b[ib].push(k);
      n += 1;
      if (n >= kw) {
        ib += 1;
        n = 0;
        b.push([]);
      }
    }
  });
  return b;
};

var haspd = function(ori_spd, lv, ori_lv, rise_spd, spd_plus) {
  return ori_spd + parseInt((lv - ori_lv) / rise_spd) + spd_plus;
};

var hadef = function(ori_def, lv, ori_lv, rise_def, def_plus) {
  return ori_def + parseInt((lv - ori_lv) / rise_def) + def_plus;
};

var urlpk = function(lek) {
  return ror("rup/pokemon/" + lek + ".png");
};

var urlball = function(ball) {
  return ror("rup/ball/" + ball + ".png");
};

var haatk = function(ori_atk, lv, plus) {
  var atk, atk1, atk2, atk3, atk4, atk5, atk6, ses;
  if (ori_atk != parseInt(ori_atk, 10)) atk = ori_atk;
  else if (ori_atk > 0) {
    ori_atk = parseInt(ori_atk);
    if (lv - 1000 <= 0) {
      atk1 = parseInt(lv / 5 + ori_atk);
      ses = lv % 5;
    } else {
      atk1 = 200 + ori_atk;
      ses = 1000 % 5;
    }
    if (lv <= 1000) atk2 = 0;
    else if (lv <= 2500) {
      if (ori_atk <= 90) atk2 = parseInt((lv - 1000 + ses) / 15);
      else if (ori_atk <= 165) atk2 = parseInt((lv - 1000 + ses) / 10);
      else atk2 = parseInt((lv - 1000 + ses) / 5);
      if (ori_atk <= 90) ses = (lv - 1000 + ses) % 15;
      else if (ori_atk <= 165) ses = (lv - 1000 + ses) % 10;
      else ses = (lv - 1000 + ses) % 5;
    } else {
      if (ori_atk <= 90) atk2 = parseInt(1500 / 15);
      else if (ori_atk <= 165) atk2 = parseInt(1500 / 10);
      else atk2 = parseInt(1500 / 5);
    }
    if (lv <= 2500) atk3 = 0;
    else if (lv <= 5000) {
      if (ori_atk <= 55) atk3 = parseInt((lv - 2500 + ses) / 20);
      else if (ori_atk <= 99) atk3 = parseInt((lv - 2500 + ses) / 15);
      else if (ori_atk <= 170) atk3 = parseInt((lv - 2500 + ses) / 10);
      else atk3 = parseInt((lv - 2500 + ses) / 5);
      if (ori_atk <= 55) ses = (lv - 2500 + ses) % 20;
      else if (ori_atk <= 99) ses = (lv - 2500 + ses) % 15;
      else if (ori_atk <= 170) ses = (lv - 2500 + ses) % 10;
      else ses = (lv - 2500 + ses) % 5;
    } else {
      if (ori_atk <= 55) atk3 = parseInt(2500 / 20);
      else if (ori_atk <= 99) atk3 = parseInt(2500 / 15);
      else if (ori_atk <= 170) atk3 = parseInt(2500 / 10);
      else atk3 = parseInt(2500 / 5);
    }
    if (lv <= 5000) atk4 = 0;
    else if (lv <= 7500) {
      if (ori_atk <= 40) atk4 = parseInt((lv - 5000 + ses) / 25);
      else if (ori_atk <= 72) atk4 = parseInt((lv - 5000 + ses) / 20);
      else if (ori_atk <= 99) atk4 = parseInt((lv - 5000 + ses) / 15);
      else if (ori_atk <= 175) atk4 = parseInt((lv - 5000 + ses) / 10);
      else atk4 = parseInt((lv - 5000 + ses) / 5);
      if (ori_atk <= 40) ses = (lv - 5000 + ses) % 25;
      else if (ori_atk <= 72) ses = (lv - 5000 + ses) % 20;
      else if (ori_atk <= 99) ses = (lv - 5000 + ses) % 15;
      else if (ori_atk <= 175) ses = (lv - 5000 + ses) % 10;
      else ses = (lv - 5000 + ses) % 5;
    } else {
      if (ori_atk <= 40) atk4 = parseInt(2500 / 25);
      else if (ori_atk <= 72) atk4 = parseInt(2500 / 20);
      else if (ori_atk <= 99) atk4 = parseInt(2500 / 15);
      else if (ori_atk <= 175) atk4 = parseInt(2500 / 10);
      else atk4 = parseInt(2500 / 5);
    }
    if (lv <= 7500) atk5 = 0;
    else if (lv <= 10000) {
      if (ori_atk <= 32) atk5 = parseInt((lv - 7500 + ses) / 30);
      else if (ori_atk <= 75) atk5 = parseInt((lv - 7500 + ses) / 25);
      else if (ori_atk <= 100) atk5 = parseInt((lv - 7500 + ses) / 20);
      else if (ori_atk <= 125) atk5 = parseInt((lv - 7500 + ses) / 15);
      else if (ori_atk <= 180) atk5 = parseInt((lv - 7500 + ses) / 10);
      else if (ori_atk <= 275) atk5 = parseInt((lv - 7500 + ses) / 5);
      else atk5 = parseInt((lv - 7500 + ses) / 3);
      if (ori_atk <= 32) ses = (lv - 7500 + ses) % 30;
      else if (ori_atk <= 75) ses = (lv - 7500 + ses) % 25;
      else if (ori_atk <= 100) ses = (lv - 7500 + ses) % 20;
      else if (ori_atk <= 125) ses = (lv - 7500 + ses) % 15;
      else if (ori_atk <= 180) ses = (lv - 7500 + ses) % 10;
      else if (ori_atk <= 275) ses = (lv - 7500 + ses) % 5;
      else ses = (lv - 7500 + ses) % 3;
    } else {
      if (ori_atk <= 32) atk5 = parseInt(2500 / 30);
      else if (ori_atk <= 75) atk5 = parseInt(2500 / 25);
      else if (ori_atk <= 100) atk5 = parseInt(2500 / 20);
      else if (ori_atk <= 125) atk5 = parseInt(2500 / 15);
      else if (ori_atk <= 180) atk5 = parseInt(2500 / 10);
      else if (ori_atk <= 275) atk5 = parseInt(2500 / 5);
      else atk5 = parseInt(2500 / 3);
      //clear
    }
    if (lv <= 10000) atk6 = 0;
    else {
      if (ori_atk <= 30) atk6 = parseInt((lv - 10000 + ses) / 40);
      else if (ori_atk <= 64) atk6 = parseInt((lv - 10000 + ses) / 30);
      else if (ori_atk <= 90) atk6 = parseInt((lv - 10000 + ses) / 25);
      else if (ori_atk <= 114) atk6 = parseInt((lv - 10000 + ses) / 20);
      else if (ori_atk <= 164) atk6 = parseInt((lv - 10000 + ses) / 15);
      else if (ori_atk <= 206) atk6 = parseInt((lv - 10000 + ses) / 10);
      else if (ori_atk <= 269) atk6 = parseInt((lv - 10000 + ses) / 5);
      else if (ori_atk <= 380) atk6 = parseInt((lv - 10000 + ses) / 3);
      else atk6 = parseInt(lv - 10000 + ses);
    }
    atk = atk1 + atk2 + atk3 + atk4 + atk5 + atk6 + plus;
  } else atk = 0;
  return atk;
};

//คำนวณค่า pp
var happ = function(ori_pp, lv, rate1, rate2, plus) {
  var pp;
  if (lv <= 2000) pp = ori_pp + parseInt(lv / rate1);
  //else pp = ori_pp + parseInt(2000 / rate1) + parseInt((lv - (rate1*parseInt(2000 / rate1)) / rate2);
  else
    pp =
      ori_pp +
      parseInt(2000 / rate1) +
      parseInt((lv - 2000 + (2000 - rate1 * parseInt(2000 / rate1))) / rate2);
  if (rate1 == 0 || rate2 == 0) pp = ori_pp;
  pp = pp + parseInt(plus);
  return pp;
};

var abss = ["po", "pa", "sl", "st", "co", "we", "fl", "fr", "bu"];

export default {
  ror,
  haskillpk,
  skidtoel,
  thatthangmot,
  sithat,
  chaektarang,
  chaektaranghash,
  haspd,
  hadef,
  haatk,
  happ,
  urlpk,
  urlball,
  abss
};
