<template>
  <div class="kaegamedata">
    <h3>ข้อมูลเกม</h3>
    <div v-for="i in khomun_game.length">
      <h4>
        ช่องที่ {{i}} <button @click="kaekhomun" style="width: 200px">บันทึก</button>
      </h4>
      <textarea v-model="khomun_game[i-1]"></textarea>
    </div>
    <button @click="khomun_game.push('')" style="width: 200px">เพิ่มช่อง</button>
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
const axios = require('axios');

export default {
  name: 'Kaegamedata',
  components: {
    
  },
  props: {
    
  },
  data: function() {
    return {
      khomun_game: [],
    }
  },
  methods: {
    saikhomun: function() {
      var url = ma.ror('gamedata')
      axios.get(url)
        .then(data => {
          Vue.set(this,"khomun_game",data.data)
        }
      )
    },
    kaekhomun: function() {
      var url = ma.ror('gamedata')
      axios.patch(url,{khomun: this.khomun_game})
        .then(data => {
          Vue.set(this,"khomun_game",data.data)
        }
      )
    }
  },
  computed: {
    
  },
  created: function() {
    this.saikhomun()
  }
}
</script>

<style scoped lang="scss">
textarea{
  width: 800px;
  height: 600px;
}
</style>
