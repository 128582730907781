<template>
  <div class="lueakphulen table">
    <table>
      <tr v-for="(km,lok) in khomun">
        <td><h5>{{lok}}</h5></td>
        <td>
          <table>
            <tr v-for="chue in km">
              <td><button @click="lueakphulen" :name="lok+'/'+chue" class="btn-sm btn-info" style="border: solid 3px #55b; width: 200px">{{chue}}</button></td>
              <td><button @click="chalopphulen(lok,chue)" class="btn-sm btn-danger">ลบ</button></td>
            </tr>
          </table>
          <div></div>
        </td>
        <td>
          <input v-model="chuephulenmai[lok]" style="width: 180px">
          <button @click="sangphulenmai(lok)" class="btn-sm btn-warning">สร้างผู้เล่นใหม่</button>
          <button @click="chaloplok(lok)" class="btn-sm btn-danger">ลบโลก</button>
        </td>
      </tr>
      <tr>
        <td colspan="3">
          
          <input v-model="chuelokmai" style="width: 180px">
          <button @click="sanglokmai" class="btn-primary btn-sm">สร้างโลกใหม่</button>
        </td>
      </tr>
    </table>
    <ami_yuenyanlop
        v-if="ilop"
        @lop="lopphulen"
        @mailop="ilop = ''"
        style="left: 100px; top: 100px"       
      />
    <ami_yuenyanlop
        v-if="iloplok"
        @lop="loplok"
        @mailop="iloplok = ''"
        style="left: 100px; top: 100px"       
      />
    <button @click="paiadmin" class="btn btn-success paiadmin">admin</button>
  </div>  
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
import ami_yuenyanlop from '@/components/ami_yuenyanlop.vue'
const axios = require('axios');

export default {
  name: 'LueakPhulen',
  components: {
    ami_yuenyanlop
  },
  data: function() {
    return {
      khomun: "",
      chuelokmai: "",
      chuephulenmai: {},
      ilop: "",
      iloplok: ""
    }
  },
  props: {
  },
  methods: {
    paiadmin: function() {
      window.location.href = "ami"
    },
    lueakphulen: function(e) {
      window.location.href = e.target.name
    },
    sanglokmai: function() {
      axios.post(ma.ror("lok"),{chuelokmai: this.chuelokmai})
        .then(data => {console.log(data.data)})
      location.reload()
    },
    sangphulenmai: function(lok) {
      axios.post(ma.ror("phulen"),{lok: lok, chuephulenmai: this.chuephulenmai[lok]})
        .then(data => {
          if(data.data) console.log(data.data)
          window.location.href = "/"+lok+"/"+this.chuephulenmai[lok]
        }
      )
    },
    chalopphulen: function(lok,phulen) {
      this.ilop = [lok,phulen]
    },
    lopphulen: function() {
      var lok = this.ilop[0]
      var phulen = this.ilop[1]
      axios.delete(ma.ror('phulen/'+phulen),{params: {lok: lok}})
        .then(data => {
          this.ilop = ""
          location.reload()
      })
    },
    chaloplok: function(lok) {
      this.iloplok = lok
    },
    loplok: function() {
      axios.delete(ma.ror('lok/'+this.iloplok))
        .then(data => {
          location.reload()
      })
    }
  },
  computed: {
  },
  created: function() {
    axios.get(ma.ror('phulen'))
      .then(data => {this.khomun = data.data})
  }
}
</script>

<style scoped lang="scss">
.lueakphulen{
  margin-top: 20px;
}
.paiadmin{
  right: 1%;
  top: 1%;
  position:fixed;
  border: solid 6px #993344
}
</style>
