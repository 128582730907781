<template>
  <div class="pokemonlak">
    <button @click="banthuekthangmot" class="btn btn-danger pumbanthuek"
      style="padding: 1px; font-size: 22px; font-weight: 800">save</button>
    <div style="border: solid 2px #119911; text-align: right; height: 46px; margin-top: 25px; font-size:20px;">
      {{ lvruam }}
      <span v-for="(pk, seq) in pklak">
        <button @click="kotlueak(seq)" style="margin: 3px; height: 36px; width: 36px"
          :class="{ pumthilueakyu: pokemonthilueak.includes(seq) }"
          :style="{ background: 'url(' + ma.ror('rup/pokemon/' + pk.pk_lek + '.png' + ')'), 'background-size': '36px' }"></button>
      </span>
    </div>
    <div>
      <div v-for="(pk, seq) in pklak" v-show="pokemonthilueak.includes(seq)" style="border: solid 4px #991111">
        <kaepkpc :pk="pk" :skill="skill[seq]" />
      </div>
      <div v-for="(pk, seq) in pklak" v-show="!pokemonthilueak.includes(seq)" style="padding: 4px">
        <kaepkpc :pk="pk" :skill="skill[seq]" />
      </div>
      <button @click="raksathangmot" style="padding: 0px; background: none; border: none"><img
          :src="ma.ror('rup/ie/pokemoncenter_logo.png')"></button>
    </div>


    <div style="position: fixed; right: 10px; bottom: 10px">
      <button @click="sadaengtarangthat = true" class="btn btn-info">ตารางธาตุ</button><br><br>
      <button @click="sadaengvirus = true" class="btn btn-info">ตารางไวรัส</button>
    </div>
    <img v-if="sadaengtarangthat" :src="ma.ror('rup/ie/element.jpg')" @click="sadaengtarangthat = false"
      style="position: fixed; left: 10px; top: 10px; height: 70%">
    <img v-if="sadaengvirus" :src="ma.ror('rup/ie/virus.jpg')" @click="sadaengvirus = false"
      style="position: fixed; left: 10px; top: 10px; height: 70%">
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
import kaepkpc from '@/components/kaepkpc.vue'
const axios = require('axios');

export default {
  name: 'Pokemonlak',
  components: {
    kaepkpc
  },
  data: function () {
    return {
      ma: ma,
      pklak: {},
      skill: {},
      sadaengtarangthat: 0,
      sadaengvirus: 0,
      pokemonthilueak: []
    }
  },
  props: {

  },
  methods: {
    saikhomun: function () {
      axios.get(ma.ror('pkpc'), { params: { pl: this.$route.params.phulen, aokhaelak: 1, hash: 1, order: "slot" } })
        .then(data => {
          Vue.set(this, "pklak", data.data)
          for (var seq in this.pklak) {
            axios.get(ma.ror("sktopkpc"), { params: { seq: seq, aoskdata: 1 } })
              .then(data => {
                var i = 0
                while (i < data.data.length) {
                  var el = ma.skidtoel(data.data[i].sk.skid)
                  if (el == "CH" | el == "MC") {
                    var effect = data.data[i].sk.effect.split(";")
                    Vue.set(data.data[i].sk, "effect", effect[0])
                    el = ma.skidtoel(effect[1].trim())
                  }
                  Vue.set(data.data[i].sk, "el", el)
                  i += 1
                }
                Vue.set(this.skill, data.data[0].pkpc_seq, data.data)
              })
          }
        }
        )

      axios.get(ma.ror('itemdata'), { params: { col: '{"gift": 1}' } })
        .then(data => {
          Vue.set(this.$store.state, "listitem_chaidai", data.data)
        }
        )
    },
    raksathangmot: function () {
      var keys = Object.keys(this.pklak)
      var i = 0
      while (i < keys.length) {
        var pk = this.pklak[keys[i]]
        pk.hp = parseInt(pk.lv) + parseInt(pk.hp_plus)
        pk.abstat = ""

        var sk = this.skill[keys[i]]
        var j = 0
        while (sk && j < sk.length) {
          Vue.set(sk[j], "pp_use", 0)
          j += 1
        }
        i += 1
      }
    },
    banthuekthangmot: function () {
      axios.patch(ma.ror("banthuekpkthangmot"), { khomun: this.pklak, skill: this.skill })
    },
    kotlueak: function (seq) {
      if (this.pokemonthilueak.includes(seq)) this.pokemonthilueak.splice(this.pokemonthilueak.indexOf(seq), 1)
      else this.pokemonthilueak.push(seq)
    }
  },
  computed: {
    lvruam: function () {
      var lvruam = 0
      console.log(this.pokemonthilueak)
      for (var seq of this.pokemonthilueak) {
        lvruam += parseInt(this.pklak[seq].lv)
      }
      return lvruam
    }
  },
  created: function () {
    this.saikhomun()
    this.$emit("pitbangna", ["pkpc"])
  }
}
</script>

<style scoped lang="scss">
.pumthilueakyu {
  border: 3px solid red;
}
</style>
