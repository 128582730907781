<template>
  <div class="kae tabedit">
    <table>
      <tr v-for="(v,k) in khomun" v-if="!['created_at','updated_at'].includes(k)">
        <td>{{k}}</td>
        <td><input v-model="khomun[k]" style="width: 400px"></td>
      </tr>
      <tr>
        <td></td>
        <td>
          <button class="btn btn-success" @click="kae">บันทึกการแก้ไข</button><button class="btn btn-warning" @click="yokloek">ยกเลิกการแก้ไข</button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';
const axios = require('axios');

export default {
  name: 'Kae',
  components: {
    
  },
  props: {
    ikae: Number
  },
  data: function() {
    return {
      khomun: []
    }
  },
  methods: {
    kae: function() {
      this.$emit('kaekhomun')
    },
    yokloek: function() {
      this.$emit('yokloek')
    }
  },
  computed: {

  },
  created: function() {
    this.khomun = this.$store.khomunkae
  }
}
</script>

<style scoped lang="scss">
.tabedit {
  position:fixed;
  left:50px;
  top:50px;
  background: #aaaabb;
  border: solid 3px;
}
</style>
