<template>
  <div class="index container" style="background: #ffeeee">
    <nalak v-if="phulen" :lok="lok" :phulen="phulen"/>
    <lueakphulen v-else />
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue';
import nalak from '@/components/nalak.vue'
import lueakphulen from '@/components/lueakphulen.vue'


export default {
  name: 'index',
  components: {
    lueakphulen,
    nalak
  },
  data: function() {
    return {
      phulen: "",
      lok: ""
    }
  },
  methods: {
    
  },
  created: function() {
    this.phulen = this.$route.params.phulen
    this.lok = this.$route.params.lok
    
  }
}
</script>
