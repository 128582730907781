<template>
  <div class="kaephulen">
    <table v-if="phulen">
      <tr>
        <td>
          <span @click="lueakkhomun(suan)" v-for="(chue,suan) in {
            'item':'ไอเท็ม',
            'heavy':'heavy',
            'mc':'microchip',
            'pkmi':'โปเกมอนที่มี',
            'pkhen':'โปเกมอนที่เห็น',
            'laekrarehen':'แลกแรร์ที่เห็น',
            'laekraremi':'แลกแรร์ที่มี',
            'unknownmi':'อันโนน',
            'pkball':'บอล',
            'badge':'เหรียญตรา',
            'card':'การ์ด',
            'kitem':'คีย์ไอเท็ม',
            'chip':'chip',
            'note': 'โน้ต',
            'mastery': 'mastery',
            'thanakhan': 'ธนาคาร',
            'phaenthi':'แผนที่',
            'uenuen':'อื่นๆ'
          }">
        
            <button :class="{btn: true,'btn-info': suanthilueak!=suan,'btn-success': suanthilueak==suan}">
              {{chue}}
            </button>
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <div v-if="khomunphulen">
            <button @click="kaekhomun" style="width: 200px">แก้ไข</button>
            <textarea v-model="khomunphulen"/>
          </div>
        </td>
      </tr>
    </table>
    <table v-else>
      <tr v-for="(km,lok) in khomun">
        <td><h5>{{lok}}</h5></td>
        <td>
          <table>
            <tr v-for="phulen in km">
              <td>
                <button @click="lueakphulen(lok,phulen)">{{phulen}}</button>
                </td>
            </tr>
          </table>
          <div></div>
        </td>
      </tr>
      <tr>
        <td colspan="3">
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
const axios = require('axios');

export default {
  name: 'Kaephulen',
  components: {
    
  },
  props: {
    khomun: Object
  },
  data: function() {
    return {
      lok: "",
      phulen: "",
      khomunphulen: "",
      suanthilueak: ""
    }
  },
  methods: {
    lueakphulen: function(lok,phulen) {
      Vue.set(this,"lok",lok)
      Vue.set(this,"phulen",phulen)
    },
    lueakkhomun: function(suan) {
      this.suanthilueak = suan
      this.saikhomunphulen()
    },
    saikhomunphulen: function() {
      var url = ma.ror('phulen/'+this.phulen)
      axios.get(url,{params: {lok: this.lok, suan: this.suanthilueak}})
        .then(data => {
          Vue.set(this,"khomunphulen",JSON.stringify(data.data[this.suanthilueak],null,2))
        }
      )
    },
    kaekhomun: function() {
      var url = ma.ror('phulen/'+this.phulen)
      axios.patch(url,{khomun: this.khomunphulen, lok: this.lok, suan: this.suanthilueak})
        .then(data => {
          Vue.set(this,"khomunphulen",JSON.stringify(data.data,null,2))
        }
      )
    }
  },
  computed: {

  },
  created: function() {
    
  }
}
</script>

<style scoped lang="scss">
textarea{
  width: 500px;
  height:600px;
}
</style>
