<template>
  <div class="dexinfo">
    <h4>ข้อมูลโปเกมอน</h4>
    <button @click="banthuek" class="btn btn-danger pumbanthuek"
      style="padding: 1px; font-size: 22px; font-weight: 800">save</button>
    <transition name="sadaengkhomun">
      <pkinfo :pkdata="pkni" :skill="sktopk[lek - 1]" :mi="pknimi" :chip="chip[lek - 1]" v-if="sadaengkhomun"
        @pitkhomun="sadaengkhomun = false" @sairailaiatkhomun="sairailaiatkhomun"
        style="height: 500px; overflow: scroll;" />
    </transition>
    <div style="text-align: left">
      <table style="width: 50%">
        <tr>
          <td>เจอแล้ว {{ pkhen.length }}</td>

          <td rowspan="2">
            <button @click="sadaengthatthichoe = !sadaengthatthichoe">แสดงธาตุที่เจอ</button>
          </td>
          <td rowspan="2">
            <button @click="sontuathiyangmaichoe = !sontuathiyangmaichoe">ซ่อนตัวที่ยังไม่เจอ</button>
          </td>
        </tr>
        <tr>
          <td>จับแล้ว {{ pkmi.length }}</td>
        </tr>
      </table>
      <div v-if="sadaengthatthichoe">
        <div v-for="that in Object.keys(thatthichoe)" :style="{ color: 'rgb' + ma.sithat[that] }">
          {{ that }}: {{ thatthichoe[that] }}
        </div>
      </div>
    </div>

    <table>
      <tr>
        <td>
          <table v-if="pkdata != ''">
            <tr v-for="pk in pkdatasadaeng" v-if="(!sontuathiyangmaichoe | pkhen.includes(pk.lek))"
              :class="{ rare: rare(pk.lek), mairare: !rare(pk.lek) }">
              <td>{{ ("00" + pk.lek).slice(-3) }}</td>
              <td style="width: 38px">
                <img :src="ma.ror('rup/pokemon/mini/' + pk.lek + '_m.png')" v-if="pkhen.includes(pk.lek)">
              </td>
              <td style="width: 300px">
                <div @click="lueakpokemon(pk.lek)" v-if="pkhen.includes(pk.lek)" class="btn-link">{{ pk.namae_th }}
                  ({{ pk.namae_en }})</div>
              </td>
              <td style="width: 150px;">
                <span v-if="pkmi.includes(pk.lek)">
                  <span :style="{ color: 'rgb' + ma.sithat[pk.ele1] }">{{ pk.ele1 }}</span>
                  <span :style="{ color: 'rgb' + ma.sithat[pk.ele2] }"> {{ pk.ele2 }}</span>
                </span>
              </td>
              <td style="width: 65px">
                <button @click="kotpum('pkhen', pk.lek)" class="btn-sm pumtick"
                  :class="{ 'btn-success': pkhen.includes(pk.lek) }">
                  เจอ
                </button>
              </td>
              <td style="width: 65px">
                <button @click="kotpum('pkmi', pk.lek)" class="btn-sm pumtick"
                  :class="{ 'btn-success': pkmi.includes(pk.lek) }" v-if="pkhen.includes(pk.lek)">
                  จับ
                </button>
              </td>
              <td style="width: 65px">
                <button @click="kotpum('laekrarehen', pk.lek)" class="btn-sm pumtick"
                  :class="{ 'pumrare': laekrarehen.includes(pk.lek) }" v-if="rare(pk.lek) && pkhen.includes(pk.lek)">
                  แรร์เจอ
                </button>
              </td>
              <td style="width: 65px">
                <button @click="kotpum('laekraremi', pk.lek)" class="btn-sm pumtick"
                  :class="{ 'pumrare': laekraremi.includes(pk.lek) }" v-if="rare(pk.lek) && pkmi.includes(pk.lek)">
                  แรร์จับ
                </button>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <button v-if="!($store.state.uenuenmi.hengen >= 3)" @click="sadaenggen(3)">แสดงโปเกมอนเพิ่ม</button>
                <span v-else-if="$store.state.uenuenmi.hengen < 6">
                  <button @click="sadaenggen($store.state.uenuenmi.hengen + 1)">.</button>
                  <button @click="sadaenggen(0)">-</button>
                </span>
                <button v-else @click="sadaenggen(0)">-</button>
              </td>
            </tr>
          </table>
        </td>
        <td style="vertical-align: top;">
          <table border="2" style="margin-left: 30px">
            <tr v-for="(a, i) in 'ABCDEFGHIJKLMNOPQRSTUVWXYZ?'">
              <td>
                <button v-if="!unknownmi.includes(a)" @click="lueakunknown(a)"
                  style="width: 50px; height: 50px; font-size: 30px;" :class="{ 'btn-info': unknownmi.includes(a) }"
                  :style="{ color: '#BBB' }">{{ a }}</button>
                <button v-else @click="lueakunknown(a)" style="width: 50px; height: 50px;"
                  :style="{ background: 'url(' + ma.ror('rup/pokemon/UnknownGif/' + a.replace('?', '_') + '.gif') + ')', 'background-repeat': 'no-repeat', 'background-height': '50px', 'background-position': 'center' }"></button>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>

  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
import pkinfo from '@/components/pkinfo.vue'
const axios = require('axios');

export default {
  name: 'Dexinfo',
  components: {
    pkinfo
  },
  data: function () {
    return {
      ma: ma,
      pkdata: [],
      sktopk: {},
      rarepokemon: [],
      lek: 0,
      sadaengkhomun: false,
      pkhen: [],
      pkmi: [],
      laekrarehen: [],
      laekraremi: [],
      pknimi: 0,
      sadaenggen3: 0,
      chip: "",
      unknownmi: [],
      sadaengthatthichoe: 0,
      sontuathiyangmaichoe: 0
    }
  },
  props: {
    cache: Array
  },
  methods: {
    saikhomun: function () {
      axios.get(ma.ror('pkdata'), {
        params: {
          "sel": "lek,namae_th,namae_en,ele1,ele2"
        }
      })
        .then(data => {
          Vue.set(this, "pkdata", data.data)
        }
        )

      axios.get(ma.ror('khomun/rarepokemon.txt'))
        .then(data => {
          Vue.set(this, "rarepokemon", data.data.trim().split("\n").map(x => parseInt(x)));
        }
        )

      var url = ma.ror('phulen/' + this.$route.params.phulen)
      var params = {
        lok: this.$route.params.lok,
        suan: "pkmi,pkhen,laekraremi,laekrarehen,unknownmi"
      }
      axios.get(url, { params: params })
        .then(data => {
          Vue.set(this, "pkmi", data.data.pkmi)
          Vue.set(this, "pkhen", data.data.pkhen)
          Vue.set(this, "laekraremi", data.data.laekraremi)
          Vue.set(this, "laekrarehen", data.data.laekrarehen)
          Vue.set(this, "unknownmi", data.data.unknownmi)
        }
        )

      axios.get(ma.ror('khomun/chiplist.csv'))
        .then(data => {
          Vue.set(this, "chip", data.data.trim().split("\n").map(x => x.split(",")));
        }
        )
    },
    sairailaiatkhomun: function (lek) {
      axios.get(ma.ror('pkdata/' + lek))
        .then(data => {
          Vue.set(this.pkdata, lek - 1, data.data)
          axios.get(ma.ror('sktopk'), { params: { lek: lek, aoskdata: 1 } })
            .then(data => {
              Vue.set(this.sktopk, lek - 1, data.data)
            })
        })
    },
    lueakpokemon: function (lek) {
      Vue.set(this, "lek", lek)
      this.sadaengkhomun = true
      this.pknimi = this.pkmi.includes(this.lek)
    },
    rare: function (lek) {
      return this.rarepokemon.includes(lek)
    },
    kotpum: function (pum, lek) {
      if (this[pum].includes(lek)) this[pum].splice(this[pum].indexOf(lek), 1)
      else this[pum].push(lek)
    },
    banthuek: function () {
      for (var i = 0; i < 5; i++) {
        var suan = ["pkhen", "pkmi", "laekrarehen", "laekraremi", "unknownmi"][i]
        axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
          lok: this.$route.params.lok,
          suan: suan,
          khomun: this[suan]
        }).then(data => {
          Vue.set(this, suan, data.data)
        }
        )
      }
      axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "uenuen",
        khomun: this.$store.state.uenuenmi
      }).then(data => {
        Vue.set(this.$store.state, "uenuenmi", data.data)
      }
      )
    },
    lueakunknown: function (a) {
      if (this.unknownmi.includes(a)) this.unknownmi.splice(this.unknownmi.indexOf(a), 1)
      else this.unknownmi.push(a)
    },
    sadaenggen: function (n) {
      Vue.set(this.$store.state.uenuenmi, "hengen", n)
    }
  },
  computed: {
    pkdatasadaeng: function () {
      if (!this.$store.state.uenuenmi.hengen) {
        return this.pkdata.slice(0, 266)
      }
      else if (this.$store.state.uenuenmi.hengen < 4) {
        return this.pkdata.slice(0, 387)
      }
      else if (this.$store.state.uenuenmi.hengen < 5) {
        return this.pkdata.slice(0, 467)
      }
      else if (this.$store.state.uenuenmi.hengen < 6) {
        return this.pkdata.slice(0, 623)
      }
      else {
        return this.pkdata.slice(0, 695)
      }
    },
    pkni: function () {
      if (!this.pkdata[this.lek - 1].ori_lv) this.sairailaiatkhomun(this.lek)
      return this.pkdata[this.lek - 1]
    },
    thatthichoe: function () {
      var x = {}
      var i = 0
      while (i < this.pkdata.length) {
        var pk = this.pkdata[i]
        if (this.pkhen.includes(pk.lek)) {
          if (Object.keys(x).includes(pk.ele1)) x[pk.ele1] += 1
          else x[pk.ele1] = 1
          if (pk.ele2) {
            if (Object.keys(x).includes(pk.ele2)) x[pk.ele2] += 1
            else x[pk.ele2] = 1
          }
        }
        i += 1
      }
      return x
    }
  },
  created: function () {
    this.saikhomun()
  }
}
</script>

<style scoped lang="scss">
.btn-link {
  cursor: pointer;
}

.pumtick {
  width: 60px;
}

.pumrare {
  background: #332222;
  color: #aaaaaa;
}

.rare {
  background: #77aaff;
}

.mairare {
  background: #eeeeff;
}

.rare:hover {
  background: #6699ff;
}

.mairare:hover {
  background: #ddddff;
}

.rare,
.mairare td {
  border: solid 1px #bbb;
}

.sadaengkhomun-enter-active {
  transition: opacity 0.5s;
}

.sadaengkhomun-enter {
  opacity: 0;
}
</style>
