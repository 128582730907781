<template>
  <div class="nalueakpokedex">
    <table :style="{'background-image': backgroundurl, 'background-size': '300px'}">
      <tr><td style="height: 113px"> </td></tr>
      <tr>
        <td>
          <table width="95">
            <tr><td @click="$emit('lueakna','dexinfo')" style="height: 100px;"> </td></tr>
            <tr><td style="height: 8px"></td></tr>
            <tr><td @click="$emit('lueakna','item')" style="height: 100px;"> </td></tr>
            <tr><td style="height: 8px"></td></tr>
            <tr><td @click="$emit('lueakna','questmas')" style="height: 100px;"> </td></tr>
          </table>
        </td>
        <td>
          <table width="95">
            <tr><td style="height: 20px"></td></tr>
            <tr><td @click="$emit('lueakna','badge')" style="height: 100px;"> </td></tr>
            <tr><td style="height: 30px"></td></tr>
            <tr><td @click="$emit('lueakna','phaenthi')" style="height: 100px;"> </td></tr>
          </table>
        </td>
        <td>
          <table width="95">
            <tr><td @click="$emit('lueakna','pokemonlak')" style="height: 100px;"> </td></tr>
            <tr><td style="height: 8px"></td></tr>
            <tr><td @click="$emit('lueakna','pkpc')" style="height: 100px;"> </td></tr>
            <tr><td style="height: 8px"></td></tr>
            <tr><td @click="$emit('lueakna','duball')" style="height: 100px;"> </td></tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
const axios = require('axios');

export default {
  name: 'Nalueakpokedex',
  components: {
    
  },
  data: function() {
    return {

    }
  },
  props: {

  },
  methods: {
    
  },
  computed: {
    backgroundurl: function() {
      return "url('"+ma.ror("rup/ie/pumlueakpokedex.png")+"')"
    }
  },
  created: function() {
    
  }
}
</script>

<style scoped lang="scss">
.nalueakpokedex td {
  height: 100px;
  width: 100px;
}
</style>
