var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"kaephulen"},[(_vm.phulen)?_c('table',[_c('tr',[_c('td',_vm._l(({
          'item':'ไอเท็ม',
          'heavy':'heavy',
          'mc':'microchip',
          'pkmi':'โปเกมอนที่มี',
          'pkhen':'โปเกมอนที่เห็น',
          'laekrarehen':'แลกแรร์ที่เห็น',
          'laekraremi':'แลกแรร์ที่มี',
          'unknownmi':'อันโนน',
          'pkball':'บอล',
          'badge':'เหรียญตรา',
          'card':'การ์ด',
          'kitem':'คีย์ไอเท็ม',
          'chip':'chip',
          'note': 'โน้ต',
          'mastery': 'mastery',
          'thanakhan': 'ธนาคาร',
          'phaenthi':'แผนที่',
          'uenuen':'อื่นๆ'
        }),function(chue,suan){return _c('span',{on:{"click":function($event){return _vm.lueakkhomun(suan)}}},[_c('button',{class:{btn: true,'btn-info': _vm.suanthilueak!=suan,'btn-success': _vm.suanthilueak==suan}},[_vm._v(" "+_vm._s(chue)+" ")])])}),0)]),_c('tr',[_c('td',[(_vm.khomunphulen)?_c('div',[_c('button',{staticStyle:{"width":"200px"},on:{"click":_vm.kaekhomun}},[_vm._v("แก้ไข")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.khomunphulen),expression:"khomunphulen"}],domProps:{"value":(_vm.khomunphulen)},on:{"input":function($event){if($event.target.composing)return;_vm.khomunphulen=$event.target.value}}})]):_vm._e()])])]):_c('table',[_vm._l((_vm.khomun),function(km,lok){return _c('tr',[_c('td',[_c('h5',[_vm._v(_vm._s(lok))])]),_c('td',[_c('table',_vm._l((km),function(phulen){return _c('tr',[_c('td',[_c('button',{on:{"click":function($event){return _vm.lueakphulen(lok,phulen)}}},[_vm._v(_vm._s(phulen))])])])}),0),_c('div')])])}),_vm._m(0)],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"3"}})])
}]

export { render, staticRenderFns }