<template>
  <div class="yuenyanlop tablop">
    <h5>แน่ใจหรือว่าจะลบ</h5>
    <button @click="$emit('lop')" class="btn btn-danger">ลบสิ</button>
    <button @click="$emit('mailop')" class="btn btn-warning">ไม่ลบละ</button>
  </div>
</template>

<script>
import Vue from 'vue';
const axios = require('axios');

export default {
  name: 'Yuenyanlop',
  components: {
    
  },
  props: {
  },
  data: function() {
    return {
      
    }
  },
  methods: {

  },
  computed: {

  },
  created: function() {

  }
}
</script>

<style scoped lang="scss">
.tablop {
  position: fixed;
  background: #aaaabb;
  border: solid 3px;
  padding: 10px;
}
.tablop button {
  margin: 10px
}
</style>
