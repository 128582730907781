<template>
  <div class="sadaengskill">
    <h3>สกิล</h3>
    lv <input v-model="pk_lv" style="width: 80px">
    <table v-if="list_lueak.length > 0" border="1" style="padding: 5px; font-size: 19px; background: #FFFFFF">
      <tr>
        <th>id</th>
        <th colspan="2">ชื่อ</th>
        <th>acc</th>
        <th>atk</th>
        <th>pp</th>
        <th colspan="2">rate</th>
        <th>cat</th>
        <th>type</th>
        <th>con</th>
        <th>sticked</th>
      </tr>
      <template v-for="i in list_lueak">
        <tr style="text-align: left">
          <th>{{ $store.state.listskill[i].skid }}</th>
          <td>{{ $store.state.listskill[i].namae_th }}</td>
          <td>{{ $store.state.listskill[i].namae_en }}</td>
          <td style="background: rgb(142,169,219)"><i>{{ $store.state.listskill[i].acc }}</i></td>
          <td style="background: rgb(255,80,80)"><b>{{ ma.haatk($store.state.listskill[i].ori_atk, pk_lv, 0) }}</b></td>
          <td style="background: rgb(198,224,180)">
            {{ ma.happ($store.state.listskill[i].ori_pp, pk_lv, $store.state.listskill[i].rate1,
              $store.state.listskill[i].rate2, 0) }}
          </td>
          <td>{{ $store.state.listskill[i].rate1 }}</td>
          <td>{{ $store.state.listskill[i].rate2 }}</td>
          <td>{{ $store.state.listskill[i].skcat }}</td>
          <td>{{ $store.state.listskill[i].sktype }}</td>
          <td>{{ $store.state.listskill[i].skcon }}</td>
          <td>{{ $store.state.listskill[i].sticked }}</td>
        </tr>
        <tr>
          <td rowspan="2" style="vertical-align: top"><button
              @click="list_lueak.splice(list_lueak.indexOf(i), 1)">ลบ</button></td>
          <td v-if="$store.state.listskill[i].effect" colspan="11" style="text-align: left">
            {{ $store.state.listskill[i].effect }}</td>
          <td v-else colspan="11">無</td>
        </tr>
        <tr>
          <td colspan="11" style="text-align: left">
            <span v-for="sktopk in pokemonskill[i]">
              <div
                v-if="sktopk.pk_lek <= 266 || sktopk.pk_lek <= { 3: 387, 4: 467, 5: 623, 6: 695 }[$store.state.uenuenmi.hengen]"
                style="display: inline-block; font-size: 10px; text-align: center">
                <img :src="ma.ror('rup/pokemon/' + sktopk.pk_lek + '.png')" width="30">
                <br>{{ sktopk.pk_lek }}
              </div>
            </span>
          </td>
        </tr>
      </template>
    </table>
    <br>
    <div style="text-align: left">
      id <input v-model="id" style="width: 70px">
      ชื่อไทย <input v-model="chue_th" style="width: 140px">
      ชื่ออังกฤษ <input v-model="chue_en" style="width: 140px"><br>
      acc <input v-model="acc[0]" style="width: 40px">
      ~ <input v-model="acc[1]" style="width: 40px">
      ori atk <input v-model="atk[0]" style="width: 60px">
      ~ <input v-model="atk[1]" style="width: 60px">
      ori pp <input v-model="pp[0]" style="width: 40px">
      ~ <input v-model="pp[1]" style="width: 40px">
    </div>
    <br>
    <table v-if="listskill.length > 0" border="1">
      <tr>
        <th>id</th>
        <th colspan="2">ชื่อ</th>
        <th>acc</th>
        <th>ori atk</th>
        <th>ori pp</th>
        <th colspan="2">rate</th>
        <th>cat</th>
        <th>type</th>
        <th>con</th>
        <th>sticked</th>
      </tr>
      <tr v-for="sk in listskill" style="text-align: left">
        <td><button @click="lueakskill(sk.i)">{{ sk.skid }}</button></td>
        <td>{{ sk.namae_th }}</td>
        <td>{{ sk.namae_en }}</td>
        <td style="background: rgb(142,169,219)"><i>{{ sk.acc }}</i></td>
        <td style="background: rgb(255,80,80)"><b>{{ sk.ori_atk }}</b></td>
        <td style="background: rgb(198,224,180)">{{ sk.ori_pp }}</td>
        <td>{{ sk.rate1 }}</td>
        <td>{{ sk.rate2 }}</td>
        <td>{{ sk.skcat }}</td>
        <td>{{ sk.sktype }}</td>
        <td>{{ sk.skcon }}</td>
        <td>{{ sk.sticked }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
const axios = require('axios');

export default {
  name: 'Sadaengkill',
  components: {

  },
  data: function () {
    return {
      ma: ma,
      id: "",
      chue_th: "",
      chue_en: "",
      acc: ["0", "100"],
      atk: ["0", "10000"],
      pp: ["0", "40"],
      list_lueak: [],
      pokemonskill: {},
      pk_lv: ""
    }
  },
  props: {

  },
  methods: {
    saikhomun: function () {
      axios.get(ma.ror("skdata"))
        .then(data => {
          this.$store.state.listskill = data.data
        })
    },
    lueakskill: function (i) {
      if (this.list_lueak.includes(i)) this.list_lueak.splice(this.list_lueak.indexOf(i), 1)
      else {
        if (!this.pokemonskill[i]) {
          axios.get(ma.ror("sktopk"), { params: { skid: this.$store.state.listskill[i].skid } })
            .then(data => {
              Vue.set(this.pokemonskill, i, data.data)
            })
        }
        this.list_lueak.push(i)
      }
    }
  },
  computed: {
    listskill: function () {
      var i = 0
      var listskill = []
      while (i < this.$store.state.listskill.length) {
        var sk = this.$store.state.listskill[i]
        if (sk.skid.includes(this.id.toUpperCase()) &
          sk.namae_en.toLowerCase().includes(this.chue_en.toLowerCase()) &
          sk.namae_th.includes(this.chue_th)
        ) {
          if (!((parseInt(sk.acc) < this.acc[0]) | (this.acc[1] < parseInt(sk.acc))) &
            !((parseInt(sk.ori_atk) < this.atk[0]) | (parseInt(this.atk[1]) < sk.ori_atk)) &
            !((sk.ori_pp < this.pp[0]) | (this.pp[1] < sk.ori_pp))
          ) {
            sk.i = i
            listskill.push(sk)
            if (listskill.length > 100) return []
          }
        }
        i++
      }
      if (this.$store.state.listskill.length == listskill.length) return []
      return listskill
    }
  },
  created: function () {
    this.saikhomun()
  }
}
</script>

<style scoped lang="scss"></style>
