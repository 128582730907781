<template>
  <div class="kaepkpc" style="text-align: left">
    <table style="font-size: 15px">
      <tr>
        <td rowspan="8" style="text-align: right; font-size: 18px; position: relative">
          <div style="width: 100px; height: 100px; position: absolute; left: 0px; top: 0px; text-align: left"
            :style="styleball">
            {{ lvball }}
            +<input v-model="buakball" style="width: 30px">
            <button @click="buakball = parseInt(buakball) + 1">+</button>
          </div>
          <div style="position: absolute; left: 0px; top: 100px; width: 150px; height:150; text-align: center">
            <img :src="ma.ror('rup/pokemon/gif/' + ('00' + pk.pk_lek).slice(-3) + '.gif')" style="width: 100%"><br>
          </div>
          <div style="width: 150px; height: 250px"></div>
        </td>
        <td style="font-size: 18px; width: 45px">{{ ("00" + pk.pk_lek).slice(-3) }}</td>
        <td colspan="3" style="font-size: 24px;">{{ pkd.namae_th }} <input v-model="pk.namaelen" style="width: 200px">
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <button @click="sadaenglaiat = 1" v-if="!sadaenglaiat" style="width: 70%; margin: 5px">แสดงรายละเอียด</button>
          <button @click="sadaenglaiat = 0" v-if="sadaenglaiat" style="width: 70%; margin: 5px">ซ่อนรายละเอียด</button>
        </td>
        <td rowspan="2" colspan="2">
          item<br>
          <span v-for="(it, i) in item_tittua">
            <span v-if="it">
              <button @click="chaiitem(i)" style="padding: 0px; width: 50px; height: 50px; font-size: 6px"
                :style="{ background: 'url(' + ma.ror('rup/item/' + it + '.jpg') + ')' }"></button>
            </span>
          </span><br>
          <select v-model="chanititemlueak">
            <option v-for="c in $store.getters.item_type_chaidai" :value="c">{{ c }}</option>
          </select>
          <div>
            <img @click="itemlueak = item.iid; saiitem(); chanititemlueak = ''" v-for="item in listitemnaitype"
              style="width: 30px; height: 30px; font-size: 10px"
              :style="{ border: { false: '', true: 'solid 2px red' }[itemlueak == item.iid] }"
              :src="ma.ror('rup/item/' + item.iid + '.jpg')">
          </div>
        </td>
      </tr>
      <tr>
        <td>LV</td>
        <td>
          <input v-model="pk.lv" style="font-size: 28px; width: 120px; background: rgb(255,230,153)">
          <button @click="phoem_lv(1)">+1</button>
          <button @click="phoem_lv(5)">+5</button>
          <button @click="phoem_lv(10)">+10</button>
        </td>
      </tr>
      <tr>
        <td>SPD</td>
        <td style="font-size: 24px;">{{ spd }}<span style="font-size: 12px">　{{ parseInt(spd*1.25) }}　{{ parseInt(spd * 1.3) }}</span></td>
        <td v-if="sadaenglaiat" colspan="2">
          SPD plus
          <input v-model="pk.spd_plus" style="width: 25px">
          <button @click="phoem_spd_plus">+</button>
        </td>
      </tr>
      <tr>
        <td>DEF</td>
        <td style="font-size: 24px;">{{ def }}<span style="font-size: 12px">　{{ parseInt(def * 1.25) }}　{{ parseInt(def * 1.3) }}</span></td>
        <td v-if="sadaenglaiat" colspan="2">
          DEF plus
          <input v-model="pk.def_plus" style="width: 25px">
          <button @click="phoem_def_plus">+</button>
        </td>
      </tr>
      <tr>
        <td style="padding: 5px">ธาตุ</td>
        <td>
          <span :style="{ color: 'rgb' + ma.sithat[pkd.ele1] }">{{ pkd.ele1 }}</span>
          <span :style="{ color: 'rgb' + ma.sithat[pkd.ele2] }" style="padding-left: 10px;">{{ pkd.ele2 }}</span>
        </td>
        <td v-if="sadaenglaiat">
          HP plus
          <input v-model="pk.hp_plus" style="width: 25px">
          <button @click="phoem_hp_plus">+</button>
        </td>
      </tr>
      <tr>
        <td colspan="2"><button @click="raksa" style="width: 70%; margin: 5px">รักษา</button></td>
        <td colspan="2">
          <span v-for="(a, i) in abstat">
            <span v-if="a">
              <img @click="aoabstatok(i)" :src="ma.ror('rup/virus/' + a + '.png')">
            </span>
            <span v-else>
              <button @click="chalueakabstat">o</button>
            </span>
          </span>
          <span v-show="sadaengabstat">
            <span v-for="r in ma.abss" @click="saiabstat(r)">
              <img :src="ma.ror('rup/virus/' + r + '.png')">
            </span>
          </span>
          <input v-model="khaabstat" style="width: 40px">
          <button @click="khaabstat = parseInt(khaabstat * 1) + 10" class="btn-sm">ทานเอ็นโดะ</button>
        </td>
      </tr>
      <tr>
        <td rowspan="1">HP</td>
        <td rowspan="1" colspan="3">
          <div style="display: inline-block">
            <div v-if="(pkd.evo_lv <= pk.lv) & (pkd.evo != null)">
              เปลี่ยนร่าง <button @click="plianrang" v-for="evo in pkd.evo.split(', ')">{{ evo }}</button> {{ pkd.evo_lv
              }}
            </div>
            <div style="font-size: 24px">
              <input v-model="pk.hp" style="width: 80px">
              / {{ hpmax }}
            </div>
          </div>
          <div style="display: inline-block">
            <input v-model="khachomti[0]" @keydown.enter="donchomti"
              style="width: 80px; height: 30px; font-size: 24px; background: #FFBB99; margin-left: 20px">
            <button @click="donchomti(0)"
              style="background: #FF6644; width: 80px; height: 28px; margin-left: 6px">โดนโจมตี</button><button
              v-for="k in [2, 3, 5, 10]" @click="pk.hp -= parseInt(pk.lv * k / 100)" class="btn-sm"
              style="height: 26px; background: #FF6644; font-size: 12px; padding: 0px; min-width: 40px;">-{{ k
              }}%</button><br>
            <input v-model="khachomti[1]" @keydown.enter="donchomti"
              style="width: 80px; height: 30px; font-size: 24px; background: #FFBB99; margin-left: 20px">
            <button @click="donchomti(1)"
              style="background: #FF6644; width: 80px; height: 28px; margin-left: 6px">โดนโจมตี</button><button
              v-for="k in [2.5, 5, 10, 30]" @click="pk.hp += parseInt(pk.lv * k / 100)" class="btn-sm"
              style="height: 26px; background: #66FF66; font-size: 12px; padding: 0px; min-width: 40px;">+{{ k
              }}%</button><br>
            <input v-model="khachomti[2]" @keydown.enter="donchomti"
              style="width: 80px; height: 30px; font-size: 24px; background: #FFBB99; margin-left: 20px">
            <button @click="donchomti(2)"
              style="background: #FF6644; width: 80px; height: 28px; margin-left: 6px">โดนโจมตี</button><button
              @click="pk.hp -= 1" class="btn-sm"
              style="height: 26px; background: #FF66FF; font-size: 12px; padding: 0px; min-width: 40px;">-1</button>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="5">
          <div style="height:30px; width:100%; background:#666">
            <div style="height:30px;" :style="{ width: khanathp, background: sihp }">
            </div>
          </div>
        </td>
      </tr>
    </table>
    <yuenyanlop v-if="idskilllueaklop != ''" @lop="lopskill" @mailop="idskilllueaklop = ''" :ilop="idskilllueaklop"
      :style="{ left: '100px', top: '100px' }" />
    <div v-show="sadaengskill">
      <table style="font-size: 14px" border="2">
        <tr style="text-align: center">
          <th></th>
          <th v-if="sadaenglaiat"></th>
          <th>สกิล</th>
          <th>ธาตุ</th>
          <th>ACC</th>
          <th width="60px" v-if="sadaenglaiat">+</th>
          <th>ATK</th>
          <th width="60px" v-if="sadaenglaiat">+</th>
          <th width="100px">PP</th>
          <th width="60px" v-if="sadaenglaiat">+</th>
          <th>type</th>
          <th>condi</th>
          <th>sticked</th>
          <th><button @click="sadaengeffect = !sadaengeffect"><b>effect</b></button></th>
        </tr>
        <tr v-for="(s, i) in skill">
          <td>
            {{ s.od + 1 }}
          </td>
          <td v-if="sadaenglaiat" style="text-align: center">
            <button @click="chalopskill(s.id)" style="font-size: 7px; width: 30px">ลบ</button><br>
            <button @click="lueanskilllong(i)" style="font-size: 7px; width: 30px">v</button>
          </td>
          <td><span style="font-size: 14px">{{ s.sk.skid }}</span> {{ s.sk.namae_th }} ({{ s.sk.namae_en }})</td>
          <td style="text-align: center" :style="{ color: 'rgb' + ma.sithat[s.sk.el] }">{{ s.sk.el }}</td>
          <td
            style="font-size: 20px; text-align: center; padding-left: 10px; padding-right: 10px; background: rgb(142,169,219)">
            <i>{{ parseInt(s.sk.acc) + parseInt(s.acc_plus) }}</i>
          </td>
          <td style="font-size: 12px;" v-if="sadaenglaiat">
            {{ parseInt(s.sk.acc) }}+<br>
            <input v-model="s.acc_plus" style="width: 25px">
            <button @click="phoem_acc_plus(s)">+</button>
          </td>
          <td
            style="font-size: 20px; text-align: center; padding-left: 15px; padding-right: 15px; background: rgb(255,80,80)">
            <b>{{ ma.haatk(s.sk.ori_atk, pk.lv, parseInt(s.atk_plus)) }}</b>
          </td>
          <td style="font-size: 12px" v-if="sadaenglaiat">
            {{ ma.haatk(s.sk.ori_atk, pk.lv, 0) }}+<br>
            <input v-model="s.atk_plus" style="width: 25px">
            <button @click="phoem_atk_plus(s)">+</button>
          </td>
          <td style="background: rgb(198,224,180); text-align: left">
            <span style="font-size: 18px"><input v-model="s.pp_use" style="width: 35px; background: rgb(153,255,153)">
              / {{ ma.happ(s.sk.ori_pp, pk.lv, s.sk.rate1, s.sk.rate2, s.pp_plus) }}</span>
            <button @click="phoem_pp_use(s)" style="font-size: 10px; margin-left: 5px">+</button>
          </td>
          <td style="font-size: 12px" v-if="sadaenglaiat">
            {{ ma.happ(s.sk.ori_pp, pk.lv, s.sk.rate1, s.sk.rate2, 0) }}+<br>
            <input v-model="s.pp_plus" style="width: 25px">
            <button @click="phoem_pp_plus(s)">+</button>
          </td>
          <td style="font-size: 12px; text-align: center">{{ s.sk.sktype }}</td>
          <td style="font-size: 12px; text-align: center">{{ s.sk.skcon }}</td>
          <td style="font-size: 12px; text-align: center">{{ s.sk.sticked }}</td>
          <td v-show="sadaengeffect">{{ s.sk.effect }}</td>
        </tr>
      </table>
      <select v-model="thatskillmai">
        <option v-for="(el) in thatthangmot" :value="el">{{ el }}</option>
      </select>
      <select v-model="skillmai">
        <option v-for="sk in skillthatni" :value="sk.skid">{{ sk.skid }} {{ sk.namae_th }} {{ sk.namae_en }}</option>
      </select>
      <button @click="phoemskill">เพิ่มสกิล</button><br>

      <span v-for="s in skill_tam_lv.skill" v-if="pk.lv >= s.lv">
        lv {{ s.lv }}: <button @click="phoemskillni(s.sk.skid)">{{ s.sk.skid }}</button>
      </span>
    </div>
    <button @click="sadaengskill = !sadaengskill">ซ่อนแสดงสกิล</button>

    <div v-if="sadaeng_natang_chai_item"
      style="position: fixed; left: 100px; top: 100px; background: #EEAAAA; padding: 7px; border: solid 4px #AA3344; width: 450px">
      <h5>
        <button style="margin: 0px; padding: 0px; width: 50px; height: 50px; font-size: 6px"
          :style="{ background: 'url(' + ma.ror('rup/item/' + item_chai.iid + '.jpg') + ')' }"></button>
        {{ item_chai.namae_th }} ({{ item_chai.namae_en }})
      </h5>
      {{ item_chai.athibai }}<br>
      <button @click="aoitemok" style="margin: 20px">ใช้ไอเท็ม</button>
      <button @click="sadaeng_natang_chai_item = 0">ยกเลิก</button>
    </div>
  </div>
</template>
  
<script>
import Vue from 'vue';
import yuenyanlop from '@/components/ami_yuenyanlop.vue'
import ma from '@/majutsu'
import TweenLite from 'gsap'

const axios = require('axios');

export default {
  name: 'Kaekpc',
  components: {
    yuenyanlop
  },
  data: function () {
    return {
      ma: ma,
      pkd: "",
      sadaengabstat: false,
      khachomti: [0, 0, 0],
      sadaengskill: false,
      thatskillmai: "",
      skillmai: "",
      idskilllueaklop: "",
      sadaengeffect: 1,
      thatthangmot: ma.thatthangmot,
      twhp: 0,
      chanititemlueak: "",
      itemlueak: "",
      sadaenglaiat: 0,
      khaabstat: 0,
      skill_tam_lv: [],
      i_item_chai: "",
      sadaeng_natang_chai_item: 0,
    }
  },
  props: {
    pk: Object,
    skill: Array
  },
  methods: {
    saikhomun: function () {
      axios.get(ma.ror('pkdata/' + this.pk.pk_lek))
        .then(data => {
          Vue.set(this, "pkd", data.data)
          this.twhp = this.pk.hp
        })

      ma.haskillpk(this.skill_tam_lv, this.pk.pk_lek, 100000, 100)
    },
    bgball: function (ball) {
      return "no-repeat url('" + ma.urlball(ball) + "')"
    },
    ele: function (skid) {
      return ma.skidtoel(skid)
    },
    phoem_lv: function (x) {
      var lvdoem = parseInt(this.pk.lv)
      var lvmai = lvdoem + x
      if ((this.pkd.evo_lv > lvdoem) & (this.pkd.evo_lv <= lvmai) & (this.pkd.evo != '')) alert("เปลี่ยนร่างได้แล้ว")
      if (this.skill_tam_lv.skill.map(s => (s.lv > lvdoem) & (s.lv <= lvmai)).reduce((a, b) => a + b) > 0) alert("มีสกิลใหม่")
      this.pk.lv = lvmai
      if (this.pk.hp != 0) this.pk.hp = parseInt(this.pk.hp) + x
    },
    donchomti: function (i) {
      this.pk.hp -= this.khachomti[i]
    },
    raksa: function () {
      this.pk.hp = this.hpmax
      this.pk.abstat = ""
      var i = 0
      while (i < this.skill.length) {
        Vue.set(this.skill[i], "pp_use", 0)
        i += 1
      }
    },
    phoem_spd_plus: function () {
      this.pk.spd_plus = parseInt(this.pk.spd_plus) + 1
    },
    phoem_def_plus: function () {
      this.pk.def_plus = parseInt(this.pk.def_plus) + 1
    },
    phoem_hp_plus: function () {
      this.pk.hp_plus = parseInt(this.pk.hp_plus) + 1
    },
    phoem_acc_plus: function (s) {
      s.acc_plus = parseInt(s.acc_plus) + 1
    },
    phoem_atk_plus: function (s) {
      s.atk_plus = parseInt(s.atk_plus) + 1
    },
    phoem_pp_use: function (s) {
      s.pp_use = parseInt(s.pp_use) + 1
    },
    phoem_pp_plus: function (s) {
      s.pp_plus = parseInt(s.pp_plus) + 1
    },
    chalueakabstat: function () {
      if (!this.abstat[2] && !this.sadaengabstat) this.sadaengabstat = true
      else this.sadaengabstat = false
    },
    saiabstat: function (a) {
      if (!this.abstat[0]) Vue.set(this.abstat, 0, a)
      else if (!this.abstat[1]) Vue.set(this.abstat, 1, a)
      else Vue.set(this.abstat, 2, a)
      Vue.set(this, "abstat", this.abstat)
      this.sadaengabstat = false
    },
    saiitem: function () {
      if (!this.item_tittua[0]) Vue.set(this.item_tittua, 0, this.itemlueak)
      else if (!this.item_tittua[1]) Vue.set(this.item_tittua, 1, this.itemlueak)
      else Vue.set(this.item_tittua, 2, this.itemlueak)
      Vue.set(this, "item_tittua", this.item_tittua)
    },
    aoabstatok: function (i) {
      this.abstat[i] = ""
      Vue.set(this, "abstat", this.abstat)
    },
    chaiitem: function (i) {
      this.i_item_chai = i
      this.sadaeng_natang_chai_item = 1
    },
    aoitemok: function () {
      this.sadaeng_natang_chai_item = 0
      this.item_tittua[this.i_item_chai] = ""
      Vue.set(this, "item_tittua", this.item_tittua)
    },
    phoemskill: function () {
      var od = 0
      while (od < this.skill.length) {
        if (this.skill[od].od != od) break
        od++
      }
      var khomun = {
        pkpc_seq: this.pk.seq,
        sk_id: this.skillmai,
        od: od
      }
      axios.post(ma.ror("sktopkpc"), { khomun: khomun })
        .then(data => {
          var s = data.data
          axios.get(ma.ror("skdata/" + s.sk_id))
            .then(data => {
              s.sk = data.data
              this.skill.push(s)
            })

          //Vue.set(this,"skill",this.skill)
        })
    },
    phoemskillni: function (skid) {
      this.skillmai = skid
      this.phoemskill()
    },
    chalopskill: function (skid) {
      this.idskilllueaklop = skid
    },
    lopskill: function () {
      axios.delete(ma.ror("sktopkpc/" + this.idskilllueaklop))
      var i = 0
      while (i < this.skill.length) {
        if (this.skill[i].id == this.idskilllueaklop) {
          this.skill.splice(i, 1)
          Vue.set(this, "skill", this.skill)
          break
        }
        i++
      }

      this.idskilllueaklop = ""
    },
    lueanskilllong: function (i) {
      if (this.skill[i + 1]) {
        this.skill[i].od += 1
        if (this.skill[i + 1].od == this.skill[i].od) this.skill[i + 1].od -= 1
        this.skill.sort(function (a, b) {
          return a.od - b.od
        })
      }
    },
    plianrang: function () {
      console.log(this.pkd.evo)
      Vue.set(this.pk, "pk_lek", parseInt(this.pkd.evo))
      this.saikhomun()
    }
  },
  computed: {
    hpmax: function () {
      return parseInt(this.pk.lv) + parseInt(this.pk.hp_plus)
    },
    sihp: function () {
      var r = parseInt((1 - this.twhp / this.hpmax) * 15).toString(16)
      var g = parseInt(this.twhp / this.hpmax * 12).toString(16)
      return "#" + r + g + "0"
    },
    khanathp: function () {
      return this.twhp / this.hpmax * 100 + "%"
    },
    abstat: {
      get: function () {
        var a = this.pk.abstat.split(" ")
        if (!a[1]) a[1] = ""
        if (!a[2]) a[2] = ""
        return a
      },
      set: function (a) {
        Vue.set(this.pk, "abstat", a[0] + " " + a[1] + " " + a[2])
      },
    },
    item_tittua: {
      get: function () {
        var a = this.pk.item.split(" ")
        if (!a[1]) a[1] = ""
        if (!a[2]) a[2] = ""
        return a
      },
      set: function (a) {
        Vue.set(this.pk, "item", a[0] + " " + a[1] + " " + a[2])
      },
    },
    spd: function () {
      return ma.haspd(this.pkd.ori_spd, this.pk.lv, this.pkd.ori_lv, this.pkd.rise_spd, this.pk.spd_plus)
    },
    def: function () {
      return ma.hadef(this.pkd.ori_def, this.pk.lv, this.pkd.ori_lv, this.pkd.rise_def, this.pk.def_plus)
    },
    skillthatni: function () {
      var i = 0
      var lis = []
      while (i < this.$store.state.listskill.length) {
        if (this.ele(this.$store.state.listskill[i].skid) == this.thatskillmai) lis.push(this.$store.state.listskill[i])
        i++
      }
      return lis
    },
    listitemnaitype: function () {
      var listi = []
      var i = 0
      while (i < this.$store.state.listitem_chaidai.length) {
        var item = this.$store.state.listitem_chaidai[i]
        if (item.itype == this.chanititemlueak) listi.push(item)
        i++
      }
      return listi
    },
    lvball: function () {
      var lv = parseInt(this.$store.getters.lv_ball[this.ball])
      if (this.buakball) lv *= (1 + 0.5 * parseInt(this.buakball))
      return lv
    },
    borderball: function () {
      if (this.lvball * 0.9 < this.pk.lv) return "solid 2px red"
      if (this.lvball * 0.75 < this.pk.lv) return "solid 2px yellow"
      return ""
    },
    styleball: function () {
      return { background: this.bgball(this.ball), 'background-size': '100px', border: this.borderball }
    },
    ball: function () {
      return this.pk.ball.split("+")[0]
    },
    buakball: {
      get: function () {
        var buakball = this.pk.ball.split("+")[1]
        if (!buakball) buakball = 0
        return buakball
      },
      set: function (a) {
        var b = this.ball
        if (a) b = b + "+" + parseInt(a)
        Vue.set(this.pk, "ball", b)
        console.log(this.pk.ball)
      },
    },
    item_chai: function () {
      return this.$store.state.listitem[this.$store.state.index_item[this.item_tittua[this.i_item_chai]]]
    }
  },
  watch: {
    'pk.hp': function (hpmai, hpdoem) {
      if (hpmai < 0) this.pk.hp = 0
      if (hpmai > this.hpmax) this.pk.hp = this.hpmax
      if (parseInt(hpmai) != hpmai) this.pk.hp = hpdoem
      TweenLite.to(this.$data, 0.5, { twhp: hpmai });
    }
  },
  created: function () {
    this.saikhomun()
    if (!this.$store.state.listskill) {
      axios.get(ma.ror("skdata"), { params: { sel: "skid,namae_th,namae_en" } })
        .then(data => {
          this.$store.state.listskill = data.data
        })
    }
    this.thatthangmot["MC"] = "MC"
    this.thatthangmot["CH"] = "CH"
  }
}
</script>

<style scoped lang="scss">
input {
  width: 50px;
}

.table td,
.table th {
  padding: 2px;
}
</style>
