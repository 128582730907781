import Vue from 'vue'
import Router from 'vue-router'
import index from './views/index.vue'
import ami from './views/ami.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
	{
      path: '/ami/:d?',
      name: 'ami',
      component: ami
    },
    {
      path: '/:lok?/:phulen?',
      name: 'index',
      component: index
    }
  ]
})
