<template>
  <span class="sadaengabstat">
    <span v-for="(a,i) in abstat">
      <span v-if="a">
        <img @click="aoabstatok(i)" :src="ma.ror('rup/virus/'+a+'.png')">
      </span>
      <span v-else>
        <button @click="chalueakabstat">o</button>
      </span>
    </span>
    <span v-show="sadaengabstat">
      <span v-for="r in ma.abss" @click="saiabstat(r)">
        <img :src="ma.ror('rup/virus/'+r+'.png')">
      </span>
    </span>
  </span>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu';

export default {
  name: 'Sadaengabstat',
  components: {
  },
  data: function() {
    return {
      ma: ma,
      abstat: ["","",""],
      sadaengabstat: false
    }
  },
  props: {
    
  },
  methods: {
    chalueakabstat: function() {
      if(!this.abstat[2]&&!this.sadaengabstat) this.sadaengabstat = true
      else this.sadaengabstat = false
    },
    saiabstat: function(a){
      if(!this.abstat[0]) Vue.set(this.abstat,0,a)
      else if(!this.abstat[1]) Vue.set(this.abstat,1,a)
      else Vue.set(this.abstat,2,a)
      this.sadaengabstat = false
    },
    aoabstatok: function(i) {
      Vue.set(this.abstat,i,"")
    }
  },
  computed: {
    
  },
  created: function() {

  }
}
</script>

<style scoped lang="scss">

</style>
