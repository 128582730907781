<template>
  <div class="pokedex">
    <table>
      <tr>
        <td style="background: #ffeedd; border: 1px solid blue; vertical-align: top; height: 500px;">
          <div @click="sonsadaengnalueak" style="font-size: 8px; font-weight: bold;">
            <div>＜＞</div>
            <nalueakpokedex v-if="sadaengnalueak" @lueakna="lueakna" />
            <div v-else>
              <div v-for="_ in 39">＜＞</div>
            </div>
          </div>
          <div v-if="sadaengnalueak" style="text-align: left">
            <table v-if="$store.state.uenuenmi" style="font-size: 36px; color: red; width: 300px">
              <tr>
                <td style="vertical-align: top">
                  <div v-for="aidi in $store.state.uenuenmi.aidi">
                    ID:<img v-for="i in aidi" :src="ma.ror('rup/ID digit/' + i + '.png')">
                    <button v-if="sadaeng_aidi" @click="lop_aidi(aidi)"
                      style="width: 15px; height: 50px; padding: 0px; opacity: 0">|</button>
                  </div>
                  <div v-if="sadaeng_aidi">
                    ID:<input v-model="aidi" style="width: 168px; height: 43px; color: red">
                  </div>
                </td>
                <td style="vertical-align: top; font-size: 24px; width: 40px">
                  <button @click="sadaeng_aidi = 1" v-if="!sadaeng_aidi"
                    style="width: 40px; height: 40px; padding: 0px">◎</button>
                  <button @click="sadaeng_aidi = 0" v-if="sadaeng_aidi"
                    style="width: 40px; height: 40px; padding: 0px">●</button>
                  <br><button @click="phoem_aidi" style="width: 40px; height: 40px; padding: 0px">+</button>
                </td>
              </tr>
            </table>
          </div>
        </td>
        <td>
          <nalueakpokedex v-show="nathilueak == 'nalueakpokedex'"
            v-if="$store.state.nathilueaklaeo.includes('nalueakpokedex')" />
          <dexinfo v-show="nathilueak == 'dexinfo'" v-if="$store.state.nathilueaklaeo.includes('dexinfo')" />
          <item v-show="nathilueak == 'item'" v-if="$store.state.nathilueaklaeo.includes('item')" />
          <badge v-show="nathilueak == 'badge'" v-if="$store.state.nathilueaklaeo.includes('badge')" />
          <questmas v-show="nathilueak == 'questmas'" v-if="$store.state.nathilueaklaeo.includes('questmas')" />
          <phaenthi v-show="nathilueak == 'phaenthi'" v-if="$store.state.nathilueaklaeo.includes('phaenthi')" />
          <pokemonlak v-show="nathilueak == 'pokemonlak'" v-if="$store.state.nathilueaklaeo.includes('pokemonlak')"
            @pitbangna="pitbangna" />
          <pkpc v-show="nathilueak == 'pkpc'" v-if="$store.state.nathilueaklaeo.includes('pkpc')"
            @pitbangna="pitbangna" />
          <duball v-show="nathilueak == 'duball'" v-if="$store.state.nathilueaklaeo.includes('duball')"
            @pitbangna="pitbangna" />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
const axios = require('axios');
import nalueakpokedex from '@/components/nalueakpokedex.vue'
import dexinfo from '@/components/dexinfo.vue'
import item from '@/components/item.vue'
import badge from '@/components/badge.vue'
import questmas from '@/components/questmas.vue'
import phaenthi from '@/components/phaenthi.vue'
import pokemonlak from '@/components/pokemonlak.vue'
import pkpc from '@/components/pkpc.vue'
import duball from '@/components/duball.vue'

export default {
  name: 'Pokedex',
  components: {
    nalueakpokedex,
    dexinfo,
    item,
    badge,
    questmas,
    phaenthi,
    pokemonlak,
    pkpc,
    duball
  },
  data: function () {
    return {
      ma: ma,
      nathilueak: "",
      sadaengnalueak: true,
      aidi: 0,
      sadaeng_aidi: 0
    }
  },
  props: {

  },
  methods: {
    lueakna: function (na) {
      this.nathilueak = na
      if (!this.$store.state.nathilueaklaeo.includes(na)) this.$store.state.nathilueaklaeo.push(na)
    },
    sonsadaengnalueak: function () {
      this.sadaengnalueak = !this.sadaengnalueak
    },
    pitbangna: function (listna) {
      var i = 0
      while (i < listna.length) {
        var ind = this.$store.state.nathilueaklaeo.indexOf(listna[i])
        if (ind != -1) this.$store.state.nathilueaklaeo.splice(ind, 1)
        i++
      }
    },
    banthuekuenuen: function () {
      axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "uenuen",
        khomun: this.$store.state.uenuenmi
      }).then(data => {
        Vue.set(this.$store.state, "uenuenmi", data.data)
      }
      )
    },
    phoem_aidi: function () {
      if (this.aidi == parseInt(this.aidi) & this.aidi.length == 6) this.$store.state.uenuenmi.aidi.push(this.aidi)
      this.banthuekuenuen()
    },
    lop_aidi: function (aidi) {
      this.$store.state.uenuenmi.aidi.splice(this.$store.state.uenuenmi.aidi.indexOf(aidi), 1)
      this.banthuekuenuen()
    }
  },
  computed: {

  },
  created: function () {

  }
}
</script>

<style scoped lang="scss"></style>
