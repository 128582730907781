var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nalueakpokedex"},[_c('table',{style:({'background-image': _vm.backgroundurl, 'background-size': '300px'})},[_vm._m(0),_c('tr',[_c('td',[_c('table',{attrs:{"width":"95"}},[_c('tr',[_c('td',{staticStyle:{"height":"100px"},on:{"click":function($event){return _vm.$emit('lueakna','dexinfo')}}})]),_vm._m(1),_c('tr',[_c('td',{staticStyle:{"height":"100px"},on:{"click":function($event){return _vm.$emit('lueakna','item')}}})]),_vm._m(2),_c('tr',[_c('td',{staticStyle:{"height":"100px"},on:{"click":function($event){return _vm.$emit('lueakna','questmas')}}})])])]),_c('td',[_c('table',{attrs:{"width":"95"}},[_vm._m(3),_c('tr',[_c('td',{staticStyle:{"height":"100px"},on:{"click":function($event){return _vm.$emit('lueakna','badge')}}})]),_vm._m(4),_c('tr',[_c('td',{staticStyle:{"height":"100px"},on:{"click":function($event){return _vm.$emit('lueakna','phaenthi')}}})])])]),_c('td',[_c('table',{attrs:{"width":"95"}},[_c('tr',[_c('td',{staticStyle:{"height":"100px"},on:{"click":function($event){return _vm.$emit('lueakna','pokemonlak')}}})]),_vm._m(5),_c('tr',[_c('td',{staticStyle:{"height":"100px"},on:{"click":function($event){return _vm.$emit('lueakna','pkpc')}}})]),_vm._m(6),_c('tr',[_c('td',{staticStyle:{"height":"100px"},on:{"click":function($event){return _vm.$emit('lueakna','duball')}}})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"height":"113px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"height":"8px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"height":"8px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"height":"20px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"height":"30px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"height":"8px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"height":"8px"}})])
}]

export { render, staticRenderFns }