<template>
  <div class="ami container">
    <ami_phulen v-if="d=='phulen'&&khomun" :khomun="khomun" />
    <ami_gamedata v-if="d=='gamedata'" />
    <div v-else-if="d">
      <h2>{{d}}</h2>
      หน้าที่ <input v-model="nathi" style="width: 50px">
      แสดง <input v-model="an" style="width: 50px"> อัน
      แสดงอันที่ {{an*(nathi-1)+1}} ถึง {{an*nathi}} จากทั้งหมด {{n_khomun}} อัน<br>
      <button @click="nathi-=1" v-if="nathi>1">หน้าที่แล้ว</button>
      <button @click="nathi+=1" v-if="nathi*an<n_khomun">หน้าถัดไป</button>
      <table class="table-dark">
        <tr>
          <th v-for="(v,k) in khomun[0]">
            {{k}}
            <div v-if="!['created_at','updated_at'].includes(k)">
              <input v-model="khomunmai[k]">
            </div>
          </th>
          <td>
            <button @click="sangmai" class="btn-sm btn-info">สร้างใหม่</button>
          </td>
        </tr>
        <tr v-for="(dat,i) in khomun" v-if="i>=an*(nathi-1)&&i<an*nathi">
          <td v-for="(v,k) in dat">
            <div @click="lueakna(i)" :class="{cu: ['created_at','updated_at'].includes(k)}">
              {{v}}
            </div>
          </td>
          <td><button @click="chalop(i,$event)" class="btn-danger">ลบ</button></td>
        </tr>
      </table>
      <ami_kae
        v-if="ikae!=-1"
        @yokloek="ikae = -1"
        @kaekhomun="kaekhomun"
        :ikae="ikae"
      />
      <ami_yuenyanlop
        v-if="ilop!=-1"
        @lop="lop"
        @mailop="ilop = -1"
        :ilop="ilop"
        :style="{left: xlop-100+'px', top: ylop+'px'}"       
      />
    </div>
    <div v-else>
      <br>
      <h4>แก้ไขฐานข้อมูล</h4>
      <h4 v-for="(a,d) in pmrk">
        <a :href="'/ami/'+d">{{d}}</a>
      </h4>
      <h4>
        <a href="/ami/phulen">ข้อมูลผู้เล่น</a>
      </h4>
      <h4>
        <a href="/ami/gamedata">ข้อมูลเกม</a>
      </h4>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
import ami_kae from '@/components/ami_kae.vue'
import ami_gamedata from '@/components/ami_gamedata.vue'
import ami_phulen from '@/components/ami_phulen.vue'
import ami_yuenyanlop from '@/components/ami_yuenyanlop.vue'
const axios = require('axios');

export default {
  name: 'ami',
  components: {
    ami_kae,
    ami_phulen,
    ami_gamedata,
    ami_yuenyanlop
  },
  data: function() {
    return {
      khomun: "",
      khomunmai: {},
      d: "",
      ikae: -1,
      ilop: -1,
      xlop: 0,
      ylop: 0,
      an: 100,
      nathi: 1,
      n_khomun: 0,
      pmrk: {
        "pkdata": "lek",
        "skdata": "skid",
        "sktopk": "id",
        "sktopkpc": "id",
        "pkpc": "seq",
        "itemdata": "iid",
        "trainer": "namae"
      }
    }
  },
  methods: {
    lueakna: function(i) {
      if(this.ikae==-1) {
        this.$store.khomunkae = JSON.parse(JSON.stringify(this.khomun[i]))
        this.ikae = i
      }
    },
    kaekhomun: function() {
      var id = this.khomun[this.ikae][this.pmrk[this.d]]
      var k = this.$store.khomunkae
      delete k.updated_at
      delete k.created_at
      axios.patch(ma.ror(this.d+'/'+id),{khomun: k})
        .then(data => {
          console.log(data.data)
          Vue.set(this.khomun,this.ikae,data.data)
          this.ikae = -1
      })
    },
    sangmai: function() {
      axios.post(ma.ror(this.d),{khomun: this.khomunmai})
        .then(data => {
          console.log(data.data)
          this.khomun.push(data.data)
          this.n_khomun += 1
      })
    },
    chalop: function(i,e) {
      this.ikae = -1
      this.xlop = e.pageX
      this.ylop = 300
      this.ilop = i
    },
    lop: function() {
      var id = this.khomun[this.ilop][this.pmrk[this.d]]
      axios.delete(ma.ror(this.d+'/'+id))
        .then(data => {
          console.log(data.data)
          this.n_khomun -= 1
          this.khomun[this.ilop] = {}
          Vue.set(this,'ilop',-1)
      })
    }
  },
  created: function() {
    this.d = this.$route.params.d
    if(this.d&&this.d!='gamedata') {
      axios.get(ma.ror(this.d))
        .then(data => {
          Vue.set(this,"khomun",data.data)
          console.log(this.d)
          this.n_khomun = this.khomun.length
          console.log(data.data)
      })
    }
  }
}
</script>

<style scoped lang="scss">
td {
  font-size: 14px;
}
th {
  font-size: 15px;
}
th input {
  width: 100%;
}
.table-dark tr:hover {
  background: #333333;
}

.cu {
  font-size: 11px;
}
</style>