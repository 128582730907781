<template>
  <div class="duball">
    <h5>บอลของ {{$route.params.phulen}}</h5>
    <div>มีทั้งหมด {{n_ball}} ลูก</div>
    <h6>===บอลมีโปเกมอน===</h6>
    <table>
      <tr v-for="px in pkx">
        <td v-for="p in px" :style="styleball(p)">
          <img @click="lueakpk(p.seq)" :src="ma.urlpk(p.pk_lek)" style="width: 150px; padding: 15px;"><br>
        </td>
      </tr>
    </table>
    <h6>===บอลเปล่า===</h6>
    <button @click="phoemball">เพิ่มบอล</button>
    <select v-model="balllueak">
      <option v-for="ball in $store.state.listball" :value="ball.iid">{{ball.namae_th}}</option>
    </select>
    <table>
      <tr v-for="bm in ballmix">
        <td v-for="ball in bm">
          {{ball}}<br>
          <img @click="lueakball(ball)" :src="ma.urlball(ball)" width="75">
        </td>
      </tr>
    </table>
    <button @click="banthuekball" class="btn btn-danger pumbanthuek" style="padding: 1px; font-size: 22px; font-weight: 800">save</button>
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
const axios = require('axios');

export default {
  name: 'Duball',
  components: {
    
  },
  data: function() {
    return {
      ma: ma,
      pk: [],
      pkballdata: [],
      balllueak: "RBall",
      pklueak: ""
    }
  },
  props: {
    
  },
  methods: {
    saikhomun: function() {
      axios.get(ma.ror('pkpc'),{params: {pl: this.$route.params.phulen, hash: 1}})
        .then(data => {
          Vue.set(this,"pk",data.data)
        }
      )
    },
    urlpk: function(lek) {
      return ma.ror('rup/pokemon/'+lek+'.png')
    },
    urlball: function(ball) {
      return ma.ror('rup/ball/'+ball+'.png')
    },
    phoemball: function() {
      if(this.balllueak in this.$store.state.ballmi) this.$store.state.ballmi[this.balllueak] += 1
      else this.$store.state.ballmi[this.balllueak] = 1
    },
    lopball: function(ball){
      this.$store.state.ballmi[ball] -= 1
      if(this.$store.state.ballmi[ball]==0) delete this.$store.state.ballmi[ball]
    },
    banthuekball: function() {
      axios.patch(ma.ror('phulen/'+this.$route.params.phulen),{
        lok: this.$route.params.lok,
        suan: "pkball",
        khomun: this.$store.state.ballmi
      }).then(data => {
          Vue.set(this.$store.state,"ballmi",data.data)
        }
      )
    },
    banthuekpk: function() {
      axios.patch(ma.ror('pkpc/'+this.pklueak),{
        khomun: this.pk[this.pklueak]
      }).then(data => {})
    },
    bgball: function(ball) {
      return "no-repeat url('"+ma.urlball(ball)+"')"
    },
    borderrup: function(pkseq) {
      if(pkseq==this.pklueak) return "solid 2px #333"
      else return "2px"
    },
    styleball: function(p) {
      return {background: this.bgball(p.ball.split("+")[0]), border: this.borderrup(p.seq)}
    },
    lueakpk: function(pkseq) {
      if(this.pklueak==pkseq) this.pklueak = ""
      else this.pklueak = pkseq
    },
    lueakball: function(ball) {
      if(this.pklueak) {
        var balldoem = this.pk[this.pklueak].ball
        if(balldoem==ball) return
        if(balldoem in this.$store.state.ballmi) this.$store.state.ballmi[balldoem] += 1
        else this.$store.state.ballmi[balldoem] = 1
        this.pk[this.pklueak].ball = ball
        this.banthuekball()
        this.banthuekpk()
        this.pklueak = ""
      }
      this.lopball(ball)
    }
  },
  computed: {
    pkx: function() {
      return ma.chaektarang(Object.values(this.pk),4)
    },
    ballmix: function() {
      return ma.chaektaranghash(this.$store.state.ballmi,8)
    },
    n_ball:function() {
      var n = 0
      var i = 0
      while(i<this.pkx.length) {
        n += this.pkx[i].length
        i += 1
      }
      var i = 0
      while(i<this.ballmix.length) {
        n += this.ballmix[i].length
        i += 1
      }
      return n
    }
  },
  created: function() {
    this.saikhomun()
    this.$emit("pitbangna",["pkpc","pokemonlak"])
  }
}
</script>

<style scoped lang="scss">
</style>
