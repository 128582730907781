import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    nathilueaklaeo: [],
    listskill: "",
    listball: [],
    listitem: [],
    index_item: {},
    ballmi: {},
    heavymi: [],
    mcmi: [],
    itemmi: [],
    badgemi: [],
    cardmi: [],
    kitemmi: [],
    chipmi: [],
    note: ["", "", "", ""],
    mastery: [],
    quest: [],
    godskill: {},
    thanakhan: [],
    n_trpk: 0,
    trpk: [],
    trpk_henlaeo: [],
    trpk_suyu: [],
    trpk_sudai: [],
    listitem_chaidai: []
  },
  getters: {
    n_sudai: state => {
      var n = 0
      var i = 0
      while (i < state.trpk_sudai.length) {
        n += state.trpk_sudai[i]
        i++
      }
      return n
    },
    item_type_chaidai: state => {
      var lisc = []
      var i = 0
      while (i < state.listitem_chaidai.length) {
        if (!lisc.includes(state.listitem_chaidai[i].itype)) lisc.push(state.listitem_chaidai[i].itype)
        i++
      }
      return lisc
    },
    lv_ball: state => {
      var lvball = {}
      var i = 0
      while (i < state.listball.length) {
        var b = state.listball[i]
        lvball[b.iid] = parseInt(b.itype)
        if (b.isubtype == "ElementBall") lvball[b.iid] += state.badgemi.length * 100
        i++
      }
      return lvball
    }
  },
  mutations: {
    increment(state) {
      state.count++
    }

  },
  actions: {

  }
})
