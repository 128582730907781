<template>
  <div class="rankha">
    <h3>ร้านค้า</h3>
    <h2>หน้านี้ยังไม่ได้สร้าง</h2>
  </div>
</template>

<script>
import Vue from 'vue';
const axios = require('axios');

export default {
  name: 'Rankha',
  components: {
    
  },
  data: function() {
    return {

    }
  },
  props: {

  },
  methods: {

  },
  computed: {

  },
  created: function() {
    
  }
}
</script>

<style scoped lang="scss">
</style>
