<template>
  <div class="trainerpk">
    <table style="text-align: left">
      <tr>
        <td rowspan="5">
          <div style="width: 150px; height: 250px; text-align: center">
            <img :src="ma.ror('rup/pokemon/gif/' + ('00' + pk.d.lek).slice(-3) + '.gif')" style="width: 100%">
          </div>
          {{ ipk + 1 }}
        </td>
        <td colspan="2">{{ ("00" + pk.d.lek).slice(-3) }} {{ pk.d.namae_th }} ({{ pk.d.namae_en }})</td>
      </tr>
      <tr>
        <td colspan="2">
          สถานะผิดปกติ
          <sadaengabstat />
          <input v-model="khaabstat" style="width: 40px">
          <button @click="khaabstat = parseInt(khaabstat * 1) + 10" class="btn-sm">ทานเอ็นโดะ</button>
        </td>
      </tr>
      <tr>
        <td style="padding-right: 15px">LV: <input v-model="pk.lv" style="width: 50px"><button
            @click="pk.lv = parseInt(pk.lv) + 1">+</button></td>
        <td>
          <span :style="{ color: 'rgb' + ma.sithat[pk.d.ele1] }">{{ pk.d.ele1 }}</span>
          <span :style="{ color: 'rgb' + ma.sithat[pk.d.ele2] }">{{ pk.d.ele2 }}</span>
        </td>
      </tr>
      <tr v-if="pk.it">
        <td>
          <button colspan="2" v-for="(it, i) in pk.it.split('|')" v-if="it" @click="chaiitem(i)"
            style="padding: 0px; width: 50px; height: 50px; font-size: 6px"
            :style="{ background: 'url(' + ma.ror('rup/item/' + it + '.jpg') + ')' }"></button>
        </td>
      </tr>
      <tr>
        <td>SPD: <b style="font-size: 22px">{{spd = ma.haspd(pk.d.ori_spd, pk.lv, pk.d.ori_lv, pk.d.rise_spd, 0)}}</b><span style="font-size: 12px">　{{ parseInt(spd * 1.25) }}　{{ parseInt(spd * 1.3) }}　</span></td>
        <td>DEF: <b style="font-size: 22px">{{ def = ma.hadef(pk.d.ori_def, pk.lv, pk.d.ori_lv, pk.d.rise_def, 0) }}</b><span style="font-size: 12px">　{{ parseInt(def * 1.25) }}　{{ parseInt(def * 1.3) }}</span></td>
      </tr>
      <tr>
        <td>HP</td>
        <td>
          <input v-model="pk.hp" style="width: 50px"> / {{ pk.lv }}
          <input v-model="khachomti[0]" style="background: #FFBB99; width: 60px">
          <button @click="donchomti(0)" class="btn-sm" style="background: #FF6644">ลด hp</button>
          <button @click="pk.hp = 0" class="btn-sm" style="height: 30px; font-size: 12px; background: #C00000">0</button>
          <button @click="pk.hp -= 1" class="btn-sm"
            style="height: 30px; background: #FF66FF; font-size: 12px; padding: 0px; min-width: 40px;">-1</button>
          <button v-for="k in [2, 3, 5, 10]" @click="pk.hp -= parseInt(pk.lv * k / 100)" class="btn-sm"
            style="height: 30px; background: #FF6644; font-size: 12px; padding: 0px; min-width: 40px;">-{{ k }}%</button><br>
          <input v-model="khachomti[1]" style="background: #FFBB99; width: 60px">
          <button @click="donchomti(1)" class="btn-sm" style="background: #FF6644">ลด hp</button>
          <input v-model="khachomti[2]" style="background: #FFBB99; width: 60px">
          <button @click="donchomti(2)" class="btn-sm" style="background: #FF6644">ลด hp</button>
          <button v-for="k in [2.5, 5, 10, 30]" @click="pk.hp += parseInt(pk.lv * k / 100)" class="btn-sm"
            style="height: 30px; background: #66FF66; font-size: 12px; padding: 0px; min-width: 40px;">+{{ k }}%</button>
        </td>
      </tr>
      <tr>
        <td>
          เปลี่ยนตัว
          <span v-for="(suyu, i) in $store.state.trpk_suyu">
            <button v-if="!suyu && $store.state.trpk_sudai[i]" @click="pliantua(i)">{{ i + 1 }}</button>
          </span>
        </td>
        <td colspan="2">
          <div style="height:30px; width:100%; background:#666">
            <div style="height:30px;" :style="{ width: khanathp, background: sihp }">
            </div>
          </div>
        </td>
      </tr>
    </table>
    <table border="2">
      <tr>
        <th></th>
        <th>สกิล</th>
        <th>ธาตุ</th>
        <th>ACC</th>
        <th>ATK</th>
        <th>PP</th>
        <th>type</th>
        <th>condi</th>
        <th>sticked</th>
        <th>effect</th>
      </tr>
      <tr v-for="(s, i) in pk.sk">
        <td><button @click="pk.sk.splice(pk.sk.indexOf(s), 1)">ปิด</button></td>
        <td>{{ s.namae_th }} ({{ s.namae_en }})</td>
        <td style="font-size: 8px" :style="{ color: 'rgb' + ma.sithat[ele(s.skid)] }">{{ ele(s.skid) }}</td>
        <td>{{ s.acc }}</td>
        <td>{{ ma.haatk(s.ori_atk, pk.lv, 0) }}</td>
        <td>
          <button @click="phoempp(pk.sk[i])">+</button>
          <input v-model="pk.sk[i].pp" style="width: 40px">
          {{ ma.happ(s.ori_pp, pk.lv, s.rate1, s.rate2, 0) }}
        </td>
        <td style="font-size: 10px">{{ s.sktype }}</td>
        <td style="font-size: 10px">{{ s.skcon }}</td>
        <td style="font-size: 10px">{{ s.sticked }}</td>
        <td>{{ s.effect }}</td>
      </tr>
    </table>
    <button @click="khaosukantosuthatpai" v-if="!yangsudai"
      style="position: fixed; right: 100px; top: 100px; background: #fba; border: solid 5px #385; padding: 10px; font-size: 30px; opacity: 0.95">
      {{ this.pk.d.namae_th }} ไม่สามารถสู้ต่อได้<br>
      <div v-if="$store.getters.n_sudai > 1">คลิกเพื่อเข้าสู่การต่อสู้ถัดไป</div>
      <div v-else>คลิกเพื่อสิ้นสุดการต่อสู้</div>
      <img :src="ma.ror('rup/ie/battle_reminder.jpg')" style="width: 221px">
    </button>
    <div v-if="sadaeng_natang_chai_item"
      style="position: fixed; left: 100px; top: 100px; background: #EEAAAA; padding: 7px; border: solid 4px #AA3344; width: 450px">
      <h5>
        <button style="margin: 0px; padding: 0px; width: 50px; height: 50px; font-size: 6px"
          :style="{ background: 'url(' + ma.ror('rup/item/' + item_chai.iid + '.jpg') + ')' }"></button>
        {{ item_chai.namae_th }} ({{ item_chai.namae_en }})
      </h5>
      {{ item_chai.athibai }}<br>
      <button @click="aoitemok" style="margin: 20px">ใช้ไอเท็ม</button>
      <button @click="sadaeng_natang_chai_item = 0">ยกเลิก</button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import sadaengabstat from '@/components/sadaengabstat.vue'
import ma from '@/majutsu';
import TweenLite from 'gsap'

export default {
  name: 'Trainerpk',
  components: {
    sadaengabstat
  },
  data: function () {
    return {
      ma: ma,
      khaabstat: 0,
      khachomti: [0, 0, 0],
      twhp: 0,
      yangsudai: true,
      i_item_chai: "",
      sadaeng_natang_chai_item: 0,
    }
  },
  props: {
    pk: Object,
    ipk: Number
  },
  methods: {
    ele: function (skid) {
      return ma.skidtoel(skid)
    },
    donchomti: function (i) {
      this.pk.hp -= this.khachomti[i]
      if (this.pk.hp < 0) this.pk.hp = 0
    },
    phoempp: function (s) {
      Vue.set(s, "pp", String(parseInt(s.pp) + 1))
      this.pk.hp -= -1
      this.pk.hp -= 1
    },
    pliantua: function (i) {
      Vue.set(this.$store.state.trpk_suyu, this.ipk, false)
      Vue.set(this.$store.state.trpk_suyu, i, true)
      Vue.set(this.$store.state.trpk_henlaeo, i, true)
      this.$emit("henlaeo", i)
    },
    sumaidailaeo: function () {
      this.yangsudai = false
    },
    khaosukantosuthatpai: function () {
      Vue.set(this.$store.state.trpk_suyu, this.ipk, false)
      Vue.set(this.$store.state.trpk_sudai, this.ipk, false)
      var i = 0
      while (i < this.$store.state.trpk_suyu.length) {
        if (this.$store.state.trpk_sudai[i] && !this.$store.state.trpk_suyu[i]) {
          Vue.set(this.$store.state.trpk_suyu, i, true)
          Vue.set(this.$store.state.trpk_henlaeo, i, true)
          this.$emit("henlaeo", i)
          break
        }
        i++
      }
    },
    chaiitem: function (i) {
      this.i_item_chai = i
      this.sadaeng_natang_chai_item = 1
    },
    aoitemok: function () {
      this.sadaeng_natang_chai_item = 0
      var it = this.pk.it.split("|")
      it[this.i_item_chai] = ""
      Vue.set(this.pk, "it", it[0] + "|" + it[1] + "|" + it[2])
    }
  },
  computed: {
    sihp: function () {
      var r = parseInt((1 - this.twhp / this.pk.lv) * 15).toString(16)
      var g = parseInt(this.twhp / this.pk.lv * 12).toString(16)
      return "#" + r + g + "2"
    },
    khanathp: function () {
      return this.twhp / this.pk.lv * 100 + "%"
    },
    item_chai: function () {
      return this.$store.state.listitem[this.$store.state.index_item[this.pk.it.split('|')[this.i_item_chai]]]
    }
  },
  watch: {
    'pk.hp': function (hpmai, hpdoem) {
      if (hpmai > this.pk.lv) this.pk.hp = this.pk.lv
      if (hpmai <= 0) this.sumaidailaeo()
      if (parseInt(hpmai) != hpmai) this.pk.hp = hpdoem
      TweenLite.to(this.$data, 0.5, { twhp: hpmai });
    }
  },
  created: function () {
    var i = 0
    while (i < this.pk.sk.length) {
      this.pk.sk[i].pp = 0
      i++
    }
    this.twhp = this.pk.hp
  }
}
</script>

<style scoped lang="scss"></style>
