<template>
  <div class="item" v-if="$store.state.uenuenmi">
    <button @click="banthuek" class="btn btn-danger pumbanthuek"
      style="padding: 1px; font-size: 22px; font-weight: 800">save</button>
    มีเงิน <input v-model="$store.state.uenuenmi.เงิน" style="width: 70px">
    <button @click="phoemngoen(ng)" v-for="ng in [-1, -5, -10, -50]" style="width: 45px">
      {{ ng }}
    </button>
    <span style="margin: 10px"></span>
    <button @click="phoemngoen(ng)" v-for="ng in [1, 5, 10, 50]" style="width: 45px">
      <b>+{{ ng }}</b>
    </button>
    <button @click="sadaengthanakhan = !sadaengthanakhan" style="margin-left:30px; width: 90px; background: #AAEE33"
      class="btn"><b>ธนาคาร</b></button>
    <div v-if="sadaengthanakhan">
      <table class="table">
        <tr>
          <th></th>
          <th>เงิน</th>
          <th>ชนิด</th>
          <th>เหลือ</th>
          <th>เทิร์น</th>
        </tr>
        <tr v-for="(tnk, i) in $store.state.thanakhan">
          <td>{{ i + 1 }}.</td>
          <td>{{ tnk.ngoen.toFixed(6) }}</td>
          <td>{{ tnk.chanit }}</td>
          <td>{{ banchingoenluea[i].toFixed(6) }}</td>
          <td>{{ tnk.turn }}</td>
          <td><button @click="$store.state.thanakhan.splice(i, 1)">-</button></td>
        </tr>
        <tr>
          <td></td>
          <td><input v-model="banchi.ngoen" style="width: 120px"></td>
          <td>
            <select v-model="banchi.chanit">
              <option v-for="a in ['ฝาก', 'ถอน', 'ดอกเบี้ย', 'เดบิต', 'เครดิต', 'ปันผล']">{{ a }}</option>
            </select>
          </td>
          <td></td>
          <td><input v-model="banchi.turn" style="width: 60px"></td>
          <td><button @click="phoembanchi">เพิ่ม</button></td>
        </tr>
      </table>
    </div>
    <div>
      <span v-for="k in [500, 100, 50, 10, 5, 1]">
        <span v-for="i in ngoenyaek[k]">
          <img :src="ma.ror('rup/money/' + k + '.png')">
        </span>
      </span>
    </div>
    <br><br>
    Heavy item มี <b style="font-size: 20px">{{ n_heavymi }}</b>/<input v-model="$store.state.uenuenmi.n_heavy"
      style="width: 40px"> อัน
    <select v-model="chanitheavylueak">
      <option v-for="c in itypeheavy" :value="c">{{ c }}</option>
    </select>
    <div>
      <button @click="heavylueak = hitem.iid; phoemheavy()" v-for="hitem in listheavynaitype"
        style="width: 60px; height: 60px; font-size: 10px"
        :style="{ background: 'url(' + ma.ror('rup/item/' + hitem.iid + '.jpg') + ')', 'background-size': '56px', border: { false: '', true: 'solid 2px red' }[heavylueak == hitem.iid] }"></button>
    </div>
    <div style="width: 600px">
      <div v-for="(h, i) in $store.state.heavymi" style="padding: 0px; vertical-align: top; display: inline-block">
        <button style="width: 100px; height: 100px"
          :style="{ background: 'url(' + ma.ror('rup/item/' + h[0] + '.jpg') + ')', 'background-size': '96px' }"><span
            style="opacity: 0.5; color: blue; font-size: 20px"></span></button><br>
        <textarea v-model="h[1]" style="width: 100px; height: 100px"></textarea>
        <br><button @click="chaiheavy(i)" style="width: 60px; height: 25px">-</button><br>
      </div>
      <div v-for="i in Math.max(0, $store.state.uenuenmi.n_heavy - n_heavymi)"
        style="padding: 0px; vertical-align: top; display: inline-block">
        <button style="width: 100px; height: 100px"></button><br>
      </div>
    </div>
    <br>

    Microchip มี <b style="font-size: 20px">{{ n_mcmi }}</b>/<input v-model="$store.state.uenuenmi.n_mc"
      style="width: 40px"> อัน
    <select v-model="mclueak">
      <option v-for="mc in listmc" :value="mc.iid">{{ mc.iid }} {{ mc.namae_th }}</option>
    </select>
    <div style="width: 600px">
      <button v-for="(mc, i) in $store.state.mcmi" @click="chaimc(i)"
        style="margin: 0px; padding: 0px; width: 40px; height: 40px; font-size: 6px"
        :style="{ background: 'url(' + ma.ror('rup/item/' + mc + '.jpg') + ')', 'background-size': '36px' }"></button>
      <span v-for="i in Math.max(0, $store.state.uenuenmi.n_mc - n_mcmi)" style="margin: 0px">
        <button @click="phoemmc" style="margin: 0px; padding: 0px; width: 40px; height: 40px; font-size: 6px"></button>
      </span>
    </div>
    <br>

    ไอเท็มทั่วไป มี <b style="font-size: 20px">{{ n_itemmi }}</b>/<input v-model="$store.state.uenuenmi.n_item"
      style="width: 40px"> อัน
    <select v-model="chanititemlueak">
      <option v-for="c in itype" :value="c">{{ c }}</option>
    </select>
    <div>
      <img @click="itemlueak = item.iid; phoemitem()" v-for="item in listitemnaitype"
        style="width: 30px; height: 30px; font-size: 10px" :src="ma.ror('rup/item/' + item.iid + '.jpg')">
    </div>
    <div style="width: 600px">
      <span v-for="(mi, item) in $store.state.itemmi" style="margin: 0px">
        <button v-for="i in mi" @click="chaiitem(item)"
          style="margin: 0px; padding: 0px; width: 50px; height: 50px; font-size: 6px"
          :style="{ background: 'url(' + ma.ror('rup/item/' + item + '.jpg') + ')', 'background-size': '46px' }"><span
            style="opacity: 0.5; color: blue"></span></button>
      </span>
      <span v-for="i in Math.max(0, $store.state.uenuenmi.n_item - n_itemmi)" style="margin: 0px">
        <button style="margin: 0px; padding: 0px; width: 50px; height: 50px; font-size: 6px"></button>
      </span>
    </div>
    <div v-if="sadaeng_natang_chai_hitem"
      style="position: fixed; left: 100px; top: 100px; background: #EEAAAA; padding: 11px; border: solid 4px #AA3344; width: 300px">
      <button @click="lopheavy" style="margin-right: 20px">ลบทิ้ง</button>
      <button @click="sadaeng_natang_chai_hitem = 0">ยกเลิก</button>
    </div>
    <div v-if="sadaeng_natang_chai_mc"
      style="position: fixed; left: 100px; top: 100px; background: #EEAAAA; padding: 11px; border: solid 4px #AA3344; width: 300px; text-align: center">
      <button style="margin: 0px; padding: 0px; width: 40px; height: 40px; font-size: 6px"
        :style="{ background: 'url(' + ma.ror('rup/item/' + $store.state.mcmi[mc_chai] + '.jpg') + ')' }"></button><br>
      <button @click="lopmc" style="margin-right: 20px">ใช้ mc</button>
      <button @click="sadaeng_natang_chai_mc = 0">ยกเลิก</button>
    </div>
    <div v-if="sadaeng_natang_chai_item"
      style="position: fixed; left: 100px; top: 100px; background: #EEAAAA; padding: 7px; border: solid 4px #AA3344; width: 450px">
      <h5>
        <button style="margin: 0px; padding: 0px; width: 50px; height: 50px; font-size: 6px"
          :style="{ background: 'url(' + ma.ror('rup/item/' + item_chai + '.jpg') + ')' }"></button>
        {{ $store.state.listitem[$store.state.index_item[item_chai]].namae_th }}
        ({{ $store.state.listitem[$store.state.index_item[item_chai]].namae_en }})
      </h5>
      {{ $store.state.listitem[$store.state.index_item[item_chai]].athibai }}<br>
      <button @click="lopitem" style="margin: 20px">ใช้ไอเท็ม</button>
      <button @click="sadaeng_natang_chai_item = 0">ยกเลิก</button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
const axios = require('axios');

export default {
  name: 'Item',
  components: {
  },
  data: function () {
    return {
      chanitheavylueak: "",
      heavylueak: "",
      mclueak: "",
      itemlueak: "",
      chanititemlueak: "",
      listheavy: [],
      listmc: [],
      hitem_chai: "",
      mc_chai: "",
      item_chai: "",
      sadaeng_natang_chai_hitem: 0,
      sadaeng_natang_chai_mc: 0,
      sadaeng_natang_chai_item: 0,
      sadaengthanakhan: 0,
      banchi: { ngoen: "", chanit: "ฝาก", turn: "" },
      ma: ma,
    }
  },
  props: {
    cache: Array
  },
  methods: {
    saikhomun: function () {
      axios.get(ma.ror('itemdata'), { params: { col: '{"icat": "heavy"}' } })
        .then(data => {
          Vue.set(this, "listheavy", data.data)
        }
        )
      axios.get(ma.ror('itemdata'), { params: { col: '{"icat": "Microchip"}' } })
        .then(data => {
          Vue.set(this, "listmc", data.data)
        }
        )
    },
    saikhomunmai: function () {
      axios.get(ma.ror('phulen/' + this.$route.params.phulen), {
        params: {
          lok: this.$route.params.lok,
          suan: "heavy,mc,item,uenuen,thanakhan"
        }
      })
        .then(data => {
          Vue.set(this.$store.state, "heavymi", data.data.heavy)
          Vue.set(this.$store.state, "mcmi", data.data.mc)
          Vue.set(this.$store.state, "itemmi", data.data.item)
          Vue.set(this.$store.state, "uenuenmi", data.data.uenuen)
          Vue.set(this.$store.state, "thanakhan", data.data.thanakhan)
        })
    },
    phoemngoen: function (ng) {
      this.$store.state.uenuenmi.เงิน = parseInt(this.$store.state.uenuenmi.เงิน) + ng
    },
    phoemheavy: function () {
      if (this.heavylueak) {
        this.$store.state.heavymi.push([this.heavylueak, ""])
        this.chanitheavylueak = ""
        this.heavylueak = ""
      }
    },
    phoemmc: function () {
      if (this.mclueak) {
        this.$store.state.mcmi.push(this.mclueak)
        this.mclueak = ""
      }
    },
    phoemitem: function () {
      if (this.itemlueak) {
        if (this.itemlueak in this.$store.state.itemmi) Vue.set(this.$store.state.itemmi, this.itemlueak, this.$store.state.itemmi[this.itemlueak] + 1)
        else Vue.set(this.$store.state.itemmi, this.itemlueak, 1)
        this.itemlueak = ""
        this.chanititemlueak = ""
      }
    },
    banthuek: function () {
      axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "heavy",
        khomun: this.$store.state.heavymi
      }).then(data => {
        Vue.set(this.$store.state, "heavymi", data.data)
      }
      )

      axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "mc",
        khomun: this.$store.state.mcmi
      }).then(data => {
        Vue.set(this.$store.state, "mcmi", data.data)
      }
      )

      axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "item",
        khomun: this.$store.state.itemmi
      }).then(data => {
        Vue.set(this.$store.state, "itemmi", data.data)
      }
      )

      axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "uenuen",
        khomun: this.$store.state.uenuenmi
      }).then(data => {
        Vue.set(this.$store.state, "uenuenmi", data.data)
      }
      )

      axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "thanakhan",
        khomun: this.$store.state.thanakhan
      }).then(data => {
        Vue.set(this.$store.state, "thanakhan", data.data)
      }
      )
    },
    chaiheavy: function (i) {
      this.hitem_chai = i
      this.sadaeng_natang_chai_hitem = 1
    },
    chaimc: function (mc) {
      this.mc_chai = mc
      this.sadaeng_natang_chai_mc = 1
    },
    chaiitem: function (item) {
      this.item_chai = item
      this.sadaeng_natang_chai_item = 1
    },
    lopheavy: function () {
      this.sadaeng_natang_chai_hitem = 0
      this.$store.state.heavymi.splice(this.hitem_chai, 1)
    },
    lopmc: function () {
      this.sadaeng_natang_chai_mc = 0
      this.$store.state.mcmi.splice(this.mc_chai, 1)
    },
    lopitem: function () {
      this.sadaeng_natang_chai_item = 0
      this.$store.state.itemmi[this.item_chai] -= 1
      if (this.$store.state.itemmi[this.item_chai] == 0) delete this.$store.state.itemmi[this.item_chai]
    },
    phoembanchi: function () {
      this.$store.state.thanakhan.push({
        ngoen: parseFloat(this.banchi.ngoen),
        chanit: this.banchi.chanit,
        turn: this.banchi.turn
      })
      console.log(this.banchi)
    }
  },
  computed: {
    itypeheavy: function () {
      var lisc = []
      var i = 0
      while (i < this.listheavy.length) {
        if (!lisc.includes(this.listheavy[i].itype)) lisc.push(this.listheavy[i].itype)
        i++
      }
      return lisc
    },
    listheavynaitype: function () {
      var listh = []
      var i = 0
      while (i < this.listheavy.length) {
        if (this.listheavy[i].itype == this.chanitheavylueak) listh.push(this.listheavy[i])
        i++
      }
      return listh
    },
    itype: function () {
      var lisc = []
      var i = 0
      while (i < this.$store.state.listitem.length) {
        if (!lisc.includes(this.$store.state.listitem[i].itype)) lisc.push(this.$store.state.listitem[i].itype)
        i++
      }
      return lisc
    },
    listitemnaitype: function () {
      var listi = []
      var i = 0
      while (i < this.$store.state.listitem.length) {
        if (this.$store.state.listitem[i].itype == this.chanititemlueak) listi.push(this.$store.state.listitem[i])
        i++
      }
      return listi
    },
    n_heavymi: function () {
      return this.$store.state.heavymi.length
    },
    n_mcmi: function () {
      return this.$store.state.mcmi.length
    },
    n_itemmi: function () {
      var i = 0
      var mi = 0
      while (i < Object.keys(this.$store.state.itemmi).length) {
        mi += this.$store.state.itemmi[Object.keys(this.$store.state.itemmi)[i]]
        i++
      }
      return mi
    },
    banchingoenluea: function () {
      var ngoen = []
      var i = 0
      while (i < this.$store.state.thanakhan.length) {
        if (i == 0) ngoen.push(this.$store.state.thanakhan[i].ngoen)
        else ngoen.push(ngoen[i - 1] + this.$store.state.thanakhan[i].ngoen)
        i += 1
      }
      return ngoen
    },
    ngoenyaek: function () {
      var ngoen = {}
      var ngoenluea = this.$store.state.uenuenmi.เงิน
      var k = [500, 100, 50, 10, 5, 1]
      var j = 0
      while (j < k.length) {
        var i = 0
        while (ngoenluea >= k[j]) {
          ngoenluea -= k[j]
          i += 1
        }
        ngoen[k[j]] = i
        j += 1
      }
      return ngoen
    }
  },
  created: function () {
    this.saikhomun()
  }
}
</script>

<style scoped lang="scss">.item {
  text-align: left
}</style>
