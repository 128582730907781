<template>
  <div class="phaenthi" style="text-align: left">

    <div>
      <table>
        <tr>
          <td v-for="(pp, i) in phaen" style="vertical-align: bottom; padding: 0px">
            <div v-for="(p, j) in pp" style="height: 22px;">
              <span v-if="pp[14 - j][0] == 'K'" style="margin: 5px;"></span>
              <button v-if="(i<10&j>2)||(i>=10 & j < 12)" @click="lueakthi(pp[14 - j])" style="width: 35px; height: 22px; font-size: 14px"
                :style="{ background: sipum(pp[14 - j]) }">{{ pp[14 - j] }}</button>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div style="position: relative; vertical-align: top; width:1000px; height: 1000px; overflow: scroll;">
      <div style="position: absolute; top: 0px; left: 0px; overflow: scroll">
        <img :src="ma.ror('rup/map/base.jpg')">
      </div>
      <div style="position: absolute; top: 0px; left: 0px; overflow: scroll">
        <img :src="ma.ror('rup/map/layout.png')">
      </div>
      <div style="position: absolute; top: 750px; left: 0px; overflow: scroll">
        <img :src="ma.ror('rup/map/base2.jpg')">
      </div>
      <div v-for="(pp, i) in phaen">
        <div v-for="p in pp" style="position: absolute; left: 0px;" :style="{ top: 750 * (i > 9) + 'px' }">
          <img v-if="sadaengthi.includes(p)" :src="ma.ror('rup/map/' + p + '.png')">
        </div>
      </div>
      <div v-for="(pp, i) in phaen">
        <div @click="lueakphaen(p)" v-for="(p, j) in pp" style="position: absolute;">
          <template v-if="i < 10">
            <div v-if="sadaengthi.includes(p)" :style="{ left: 58 + 83 * i + 'px', top: 25 + 55 * (11 - j) + 'px' }"
              style="width: 83px; height: 55px; position: absolute; top: 0px; left: 0px;"></div>
            <img v-if="sadaengphaen.includes(p)" :src="ma.ror('rup/map/detail/' + p + '.jpg')"
              style="position: absolute; top: 0px; left: 0px;">
          </template>
          <template v-else>
            <div v-if="sadaengthi.includes(p)" :style="{ left: 60 + 83 * (i - 10) + 'px', top: 925 + 55 * (11 - j) + 'px' }"
              style="width: 83px; height: 55px; position: absolute; top: 0px; left: 0px;"></div>
            <div style="position: absolute; top: 750px; left: 0px;">
              <img v-if="sadaengphaen.includes(p)" :src="ma.ror('rup/map/detail/' + p + '.png')">
            </div>
          </template>
        </div>
      </div>
    </div>
    <button @click="banthuek" class="btn btn-danger pumbanthuek"
      style="padding: 1px; font-size: 22px; font-weight: 800">save</button>
    <!--
    <button @click="klumthilueak=0" :class="{klumthilueak: klumthilueak==0}">Road</button>
    <button @click="klumthilueak=1" :class="{klumthilueak: klumthilueak==1}">City and Town</button>
    <button @click="klumthilueak=2" :class="{klumthilueak: klumthilueak==2}">Notable Geological Feature</button>
    <div>
      <span v-if="klumthilueak==0">
        <button v-for="t in thanon" @click="lueakthi(t)" style="margin: 0px; padding: 0px" :style="{background: sipum(t)}">{{t}}</button>
      </span>
      <span v-if="klumthilueak==1">
        <button v-for="t in mueang" @click="lueakthi(t)" style="margin: 0px; padding: 0px" :style="{background: sipum(t)}">{{t}}</button>
      </span>
      <span v-if="klumthilueak==2">
        <button v-for="t in thi" @click="lueakthi(t)" style="margin: 0px; padding: 0px" :style="{background: sipum(t)}">{{t}}</button>
      </span>
    </div>
    <div style="position: relative; vertical-align: top; width:1000px; height: 1000px; overflow: scroll;">
      <div style="position: absolute; top: 0px; left: 0px; overflow: scroll">
        <img :src="ma.ror('rup/map/phaenthi1.jpg')">
      </div>
      <div v-for="t in thi.concat(mueang).concat(thanon)" style="position: absolute; top: 0px; left: 0px;">
        <img v-if="sadaengthi.includes(t)" :src="ma.ror('rup/map/'+t+'.png')">
      </div>
      <div style="position: absolute; top: 0px; left: 927px;">
        <img :src="ma.ror('rup/map/phaenthi2.png')">
      </div>
    </div>
  -->
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
const axios = require('axios');

var mueang = [
  "Cujiba city", "Guran town", "Hanada city",
  "Nibi city", "Masara town", "Sekijiku city",
  "Shion town", "Tamabushi city", "Tokiwa city",
  "Vermil city", "Yamabugi city"
]
var thi = [
  "Ash Vocano", "Denji mountain", "Digda tunnel",
  "Indogi plateau", "Pippy cave",
  "Safari zone", "Tokiwa Forest"
]
var thanon = []
var i = 1
while (i < 18) {
  thanon.push("R" + i)
  i += 1
}

var phaen = []
var i = 0
while (i < 20) {
  var j = 1
  var p = []
  while (j < 16) {
    p.push("ABCDEFGHIJKLMNOPQRST"[i] + j)
    j += 1
  }
  phaen.push(p)
  i += 1
}

export default {
  name: 'Phaenthi',
  components: {
  },
  data: function () {
    return {
      ma: ma,
      phaen: phaen,
      mueang: mueang,
      thi: thi,
      thanon: thanon,
      sadaengthi: [],
      klumthilueak: 0,
      sadaengphaen: []
    }
  },
  props: {
    cache: Array
  },
  methods: {
    saikhomun: function () {
      var url = ma.ror('phulen/' + this.$route.params.phulen)
      var params = {
        lok: this.$route.params.lok,
        suan: "phaenthi"
      }
      axios.get(url, { params: params })
        .then(data => {
          Vue.set(this, "sadaengthi", data.data.phaenthi)
          console.log(this.sadaengthi)
        }
        )
    },
    lueakthi: function (thi) {
      if (this.sadaengthi.includes(thi)) {
        this.sadaengthi.splice(this.sadaengthi.indexOf(thi), 1)
      }
      else {
        this.sadaengthi.push(thi)
      }
    },
    lueakphaen: function (p) {
      if (this.sadaengphaen.includes(p)) {
        this.sadaengphaen.splice(this.sadaengphaen.indexOf(p), 1)
      }
      else {
        this.sadaengphaen.push(p)
      }
    },
    sipum: function (thi) {
      if (this.sadaengthi.includes(thi))
        return "#FFAAAA"
      else
        return "#EEDDDD"
    },
    banthuek: function () {
      axios.patch(ma.ror('phulen/' + this.$route.params.phulen), {
        lok: this.$route.params.lok,
        suan: "phaenthi",
        khomun: this.sadaengthi
      }).then(data => {
        Vue.set(this, "sadaengthi", data.data)
      }
      )
    }
  },
  computed: {

  },
  created: function () {
    this.saikhomun()
  }
}
</script>

<style scoped lang="scss">
.klumthilueak {
  background: #AA7799;
}
</style>
