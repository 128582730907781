<template>
  <div class="pkpc">
    <div>
      <button @click="lueakmodechatkan('kae')" class="btn" :class="{'btn-info': modechatkan=='kae'}">แก้ไขข้อมูล</button>
      <button @click="lueakmodechatkan('yai')" class="btn" :class="{'btn-info': modechatkan=='yai'}">โยกย้าย</button>
      <button @click="lueakmodechatkan('lop')" class="btn" :class="{'btn-info': modechatkan=='lop'}">ลบทิ้ง</button>
    </div>
    <img :src="ma.ror('rup/ie/main-pokemon.png')">
    <table v-if="pkdata">
      <tr v-for="px in pkx.lak">
        <td v-for="p in px" v-if="pkdata[p.pk_lek]">
          <div class="kropball" :style="{background: bgball(p.ball.split('+')[0]), 'background-size': '150px', border: borderrup(p.slot)}">
            <img @click="kotpklak(p.slot)" :src="ma.urlpk(p.pk_lek)">
          </div>
          {{pkdata[p.pk_lek].namae_th}}<br>
          {{p.slot}}
        </td>
      </tr>
    </table>
    <img :src="ma.ror('rup/ie/pc-pokemon.png')">
    <table v-if="pkdata">
      <tr v-for="px in pkx.fak">  
        <td v-for="p in px" v-if="pkdata[p.pk_lek]">
          <div class="kropball" :style="{background: bgball(p.ball.split('+')[0]), 'background-size': '150px', border: borderruppkfak(p.seq)}">
            <img @click="kotpkfak(p.seq)" :src="ma.urlpk(p.pk_lek)"><br>
          </div>
          {{pkdata[p.pk_lek].namae_th}}
        </td>
      </tr>
    </table>
    <div>
      <button @click="loppkfak" class="btn btn-danger" v-if="modechatkan=='lop'">ลบทิ้งทันที</button>
    </div>
    <transition name="sadaengkhomun">
      <div id="chongkae" v-if="pklueakkae">
        <kaepkpc :pk="pklueakkae" :skill="skillpklueakkae" />
        <button @click="banthuekpkkae">บันทึกความเปลี่ยนแปลง</button>
        <button @click="yokloekkae">ยกเลิก</button>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
import kaepkpc from '@/components/kaepkpc.vue'
const axios = require('axios');

export default {
  name: 'Pkpc',
  components: {
    kaepkpc
  },
  data: function() {
    return {
      ma: ma,
      pkruam: [],
      pkdata: [],
      pklaklueak: "",
      modechatkan: "kae",
      pklueakkae: "",
      pklueakkae_seq: "",
      skillpklueakkae: [],
      pklueaklop: "",
    }
  },
  props: {
    
  },
  methods: {
    saikhomun: function() {
      axios.get(ma.ror('pkpc'),{params: {pl: this.$route.params.phulen}})
        .then(data => {
          Vue.set(this,"pkruam",data.data)
        }
      )

      axios.get(ma.ror('pkdata'), {
          params: {
            sel: "lek,namae_th",
            hash: 1
          }
        })
        .then(data => {
          Vue.set(this,"pkdata",data.data)
          console.log(data.data)
        }
      )

      axios.get(ma.ror('itemdata'),{params: {col: '{"gift": 1}'}})
        .then(data => {
          Vue.set(this.$store.state,"listitem_chaidai",data.data)
        }
      )
    },
    banthuekpkkae: function() {
      var seq = this.pklueakkae.seq
      axios.patch(ma.ror('pkpc/'+this.pklueakkae_seq),{
        khomun: this.pklueakkae
        }).then(data => {
          this.pklueakkae_seq = seq
          if(this.skillpklueakkae.length) this.banthuek_sk(this.skillpklueakkae.length-1)
      })
      this.pklueakkae = ""
      this.saikhomun()
    },
    yokloekkae: function() {
      this.pklueakkae = ""
    },
    banthuek_sk: function(i) {
      var sk = this.skillpklueakkae[i]
      delete sk.sk
      sk.pkpc_seq = this.pklueakkae_seq
      axios.patch(ma.ror("sktopkpc/"+sk.id),{khomun: sk})
        .then(data => {
          if(i>0) this.banthuek_sk(i-1)
          console.log(data.data)
      })
    },
    yaeklakfak: function(d) {
      var pk = {lak: {}, fak: {}}
      var i = 0
      while(i<d.length) {
        if(d[i].slot>0) pk.lak[d[i].slot] = d[i]
        else pk.fak[d[i].seq] = d[i]
        i++
      }
      return pk
    },
    bgball: function(ball) {
      return "no-repeat url('"+ma.urlball(ball)+"')"
    },
    borderrup: function(slot) {
      if(slot==this.pklaklueak) return "solid 2px #333"
      else return "2px"
    },
    borderruppkfak: function(seq) {
      if(seq==this.pklueaklop) return "solid 2px #333"
      else return "2px"
    },
    kotpklak: function(slot) {
      if(this.modechatkan=="kae") 0
      else if(this.modechatkan=="yai") this.lueakpklak(slot)
    },
    kotpkfak: function(pkseq) {
      if(this.modechatkan=="kae") {
        if(this.pklueakkae=="") {
          this.anpkkae(pkseq)
        }
      }
      else if(this.modechatkan=="yai") this.lueakpkfak(pkseq)
      else if(this.modechatkan=="lop") this.pklueaklop = pkseq
    },
    anpkkae: function(pkseq) {
      axios.get(ma.ror('pkpc/'+pkseq))
        .then(data => {
          Vue.set(this,"pklueakkae",data.data)
          Vue.set(this,"pklueakkae_seq",pkseq)
          axios.get(ma.ror('sktopkpc'),{params: {seq: this.pklueakkae_seq, aoskdata: 1}})
            .then(data => {
              var i = 0
              while(i<data.data.length) {
                var el = ma.skidtoel(data.data[i].sk.skid)
                if(el=="CH"|el=="MC") {
                  var effect = data.data[i].sk.effect.split(";")
                  Vue.set(data.data[i].sk,"effect",effect[0])
                  el = ma.skidtoel(effect[1].trim())
                }
                Vue.set(data.data[i].sk,"el",el)
                i += 1
              }
              Vue.set(this,"skillpklueakkae",data.data)
          })
        }
      )
    },
    lueakpklak: function(slot) {
      if(!this.pklaklueak) this.pklaklueak = slot
      else if(this.pklaklueak==slot) {
        var seq = this.pk.lak[this.pklaklueak].seq
        this.pk.lak[this.pklaklueak].slot = 0
        this.banthuekpkfak(seq)
        Vue.set(this,"pklaklueak","")
      }
      else {
        var temp = parseInt(this.pk.lak[slot].slot)
        this.pk.lak[slot].slot = parseInt(this.pk.lak[this.pklaklueak].slot)
        this.pk.lak[this.pklaklueak].slot = temp
        this.banthuekpklak(this.pklaklueak)
        this.banthuekpklak(slot)
        Vue.set(this,"pklaklueak","")
      }
    },
    lueakpkfak: function(pkseq) {
      if(this.pklaklueak) {
        var seq = this.pk.lak[this.pklaklueak].seq
        this.pk.lak[this.pklaklueak].slot = 0
        this.pk.fak[pkseq].slot = this.pklaklueak
        this.banthuekpklak(this.pklaklueak)
        this.banthuekpkfak(seq)
        Vue.set(this,"pklaklueak","")
      }
      else {
        var i = 1
        while(i<100){
          if(!this.pk.lak[i]) break
          i++
        }
        this.pk.fak[pkseq].slot = i
        this.banthuekpklak(i)
      }
    },
    banthuekpklak: function(slot) {
      var pkseq = this.pk.lak[slot].seq
      axios.patch(ma.ror('pkpc/'+pkseq),{
        khomun: this.pk.lak[slot]
      }).then(data => {
        return data.data
      })
    },
    banthuekpkfak: function(pkseq) {
      axios.patch(ma.ror('pkpc/'+pkseq),{
        khomun: this.pk.fak[pkseq]
      }).then(data => {
        return data.data
      })
    },
    loppkfak: function() {
      axios.delete(ma.ror('pkpc/'+this.pklueaklop))
        .then(data => {
          this.saikhomun()
          axios.delete(ma.ror("lopseqsktopkpc/"+this.pklueaklop),)
          //this.pklueaklop
          //axios.all(sk.map(this.lopskill)
      })
      
    },
    lueakmodechatkan: function(mode) {
      this.modechatkan = mode
      this.pklaklueak = ""
      this.pklueaklop = ""
      this.saikhomun()
    }
  },
  computed: {
    pk: function() {
      return this.yaeklakfak(this.pkruam)
    },
    pkx: function() {
      return {lak: ma.chaektarang(Object.values(this.pk.lak),4), fak: ma.chaektarang(Object.values(this.pk.fak),4)}
    }
  },
  created: function() {
    this.saikhomun()
    this.$emit("pitbangna",["pokemonlak","duball"])
  }
}
</script>

<style scoped lang="scss">
#chongkae{
  position:fixed;
  left:20px;
  top:20px;
  background: #ffffff;
  height: 500px;
  overflow: scroll;
}

.sadaengkhomun-enter-active {
  transition: opacity 0.5s;
}
.sadaengkhomun-enter {
  opacity: 0;
}
.kropball {
  width: 150px;
  height: 150px;
  padding: 25px;
}
.kropball img {
  width: 100px
}
</style>
