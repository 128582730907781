<template>
  <div class="pkinfo panel-text tabpkinfo">
    <table style="text-align: left">
      <tr>
        <td @click="$emit('pitkhomun')">
          <img :src="urlrup(pkdata.lek)">
        </td>
        <td>
          <table class="table">
            <tr>
              <td>id</td>
              <td>{{ pkdata.lek }}</td>
            </tr>
            <tr>
              <td>ชื่อ</td>
              <td>{{ pkdata.namae_th }} ({{ pkdata.namae_en }})</td>
            </tr>
            <tr>
              <td>ori lv</td>
              <td>{{ pkdata.ori_lv }}</td>
            </tr>
            <tr>
              <td>ori SPD</td>
              <td>{{ pkdata.ori_spd }}</td>
            </tr>
            <tr>
              <td>ori DEF</td>
              <td>{{ pkdata.ori_def }}</td>
            </tr>
            <tr style="font-size: 12px">
              <td>rise SPD</td>
              <td v-if="mi">{{ pkdata.rise_spd }}</td>
              <td v-else>?</td>
            </tr>
            <tr style="font-size: 12px">
              <td>rise DEF</td>
              <td v-if="mi">{{ pkdata.rise_def }}</td>
              <td v-else>?</td>
            </tr>
            <tr>
              <td>ธาตุ</td>
              <td>{{ pkdata.ele1 }}<span v-if="pkdata.ele2">{{ "," + pkdata.ele2 }}</span></td>
            </tr>
            <tr v-if="mi">
              <td>วิวัฒนาการ</td>
              <td v-if="pkdata.evo"><span v-for="e in pkdata.evo.split(',')"><img :src="urlrupmini(e.trim())"></span> LV
                {{ pkdata.evo_lv }}</td>
              <td v-else>-</td>
            </tr>
            <tr v-if="mi">
              <td colspan="2">
                ที่ lv <input v-model="lv" style="width: 60px">
                DEF {{ ma.hadef(pkdata.ori_def, lv, pkdata.ori_lv, pkdata.rise_def, 0) }}
                SPD {{ ma.haspd(pkdata.ori_spd, lv, pkdata.ori_lv, pkdata.rise_spd, 0) }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <div v-if="mi">
      <table class="table">
        <tr>
          <th>ที่ lv</th>
          <th></th>
          <th>สกิล</th>
          <th>ธาตุ</th>
          <th>acc</th>
          <th>ori atk</th>
          <th>ori pp</th>
          <th>cat</th>
          <th>type</th>
          <th>condi</th>
          <th>sticked</th>
          <th><button @click="sadaengeffect = !sadaengeffect">effect</button></th>
        </tr>
        <tr v-for="s in skill">
          <td>{{ s.lv }}</td>
          <td>{{ s.sk.skid }}</td>
          <td>{{ s.sk.namae_th }} ({{ s.sk.namae_en }})</td>
          <td>{{ ma.skidtoel(s.sk.skid) }}</td>
          <td>{{ s.sk.acc }}</td>
          <td>{{ s.sk.ori_atk }}</td>
          <td>{{ s.sk.ori_pp }}</td>
          <td>{{ s.sk.skcat }}</td>
          <td>{{ s.sk.sktype }}</td>
          <td>{{ s.sk.skcon }}</td>
          <td>{{ s.sk.sticked }}</td>
          <td v-if="sadaengeffect">{{ s.sk.effect }}</td>
        </tr>
      </table>
      <b>C h i p</b>
      <table>
        <tr>
          <td v-for="c in 'Cut,Surf,Waterfall,Tide,Run,Fly,Rock smash,Flash,Dive'.split(',')"
            style="width: 90px; border: solid 1px #999">{{ c }}</td>
        </tr>
        <tr style="font-size: 22px">
          <td v-for="c in chip" style="border: solid 1px #999">
            <div v-if="c.trim() == '1'" style="color: #00dd00">〇</div>
            <div v-else style="color: #dd0000">ㄨ</div>
          </td>
        </tr>
        <tr>
          <td colspan="9"><br><b>M i c r o c h i p</b><br><img
              :src="ma.ror('rup/chip/MC/MC_' + ('00' + pkdata.lek).slice(-3) + '.jpg')" style="width: 600px"></td>
        </tr>
      </table><br>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import ma from '@/majutsu'
const axios = require('axios');

export default {
  name: 'Pkinfo',
  components: {

  },
  data: function () {
    return {
      ma: ma,
      lv: 10000,
      sadaengeffect: 0
    }
  },
  props: {
    pkdata: Object,
    skill: Array,
    mi: Boolean,
    chip: Array
  },
  methods: {
    urlrup: function (lek) {
      return ma.ror('rup/pokemon/' + lek + '.png')
    },
    urlrupmini: function (lek) {
      return ma.ror('rup/pokemon/mini/' + lek + '_m.png')
    }
  },
  computed: {
  },
  created: function () {
    if (this.pkdata.ori_lv) this.lv = this.pkdata.ori_lv
  }
}
</script>

<style scoped lang="scss">
.tabpkinfo {
  position: fixed;
  left: 50px;
  top: 50px;
  background: #ffffff;
}

.table {
  text-align: left;
  font-size: 14px;
  padding: 2px;
}</style>
